<template>
    <div v-if="player" class="fixture-list-players-row">
        <span v-if="isCaptain" class="captain-icon">
            <i class="fa-solid fa-star"></i>
        </span>
        <span v-if="isViceCaptain" class="vice-captain-icon">
            <i class="fa-solid fa-star"></i>
        </span>
        <span class="player-name">
            <span class="hidden md:inline">{{ player.name }}</span>
            <span class="inline md:hidden">{{ player.display_name }}</span>
        </span>
        <span v-if="playerFixture" class="player-score">
            {{ points }}
        </span>
        <span v-else class="player-score">
            {{ player.position }}
        </span>
    </div>
</template>

<script>
import {computed} from "vue";

export default {
    props: {
        fixture: {
            type: Object,
            required: true
        },
        playerFixture: {
            type: Object,
            default: null
        },
        pick: {
            type: Object,
            default: null
        },
        player: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const points = computed(() => {
            return (_.get(props.playerFixture, 'points') || 0) * (_.get(props.pick, 'multiplier') || 1)
        })

        const isCaptain = computed(() => {
            return _.get(props.pick, 'is_captain')
        })

        const isViceCaptain = computed(() => {
            return _.get(props.pick, 'is_vice_captain')
        })

        return {points, isCaptain, isViceCaptain}
    }
}
</script>
