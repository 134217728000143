const columns = {
    info_icon: {
        label: 'Info',
        abbreviation: '',
        field: 'info_icon',
        description: 'Load more information about the player',
        models: ['player_season', 'player_fixture', 'player_gameweek']
    },
    name: {
        label: 'Name',
        abbreviation: 'Name',
        field: 'name',
        description: 'The players name',
        sortable: true,
        sort_dir: 'name',
        models: ['player_season', 'player_fixture', 'player_gameweek']
    },
    position: {
        label: 'Position',
        abbreviation: 'P',
        field: 'position',
        description: 'What position the player plays',
        sortable: true,
        sort_field: 'position_sort',
        sort_dir: 'asc',
        models: ['player_season', 'player_fixture', 'player_gameweek']
    },
    value: {
        label: 'Value',
        abbreviation: '$',
        field: 'value',
        description: 'How much the player costs',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_gameweek']
    },
    team: {
        label: 'Team',
        abbreviation: 'Team',
        field: 'team',
        description: 'The name of the team',
        sortable: true,
        sort_dir: 'asc',
        models: ['player_season', 'player_fixture', 'player_gameweek']
    },
    selected_by: {
        label: 'Selected By Percentage',
        abbreviation: 'Sel %',
        field: 'selected_by_percent',
        description: 'The percentage of teams that own this player',
        sortable: true,
        sort_field: 'selected_by',
        suffix: ' %',
        models: ['player_season', 'player_fixture', 'player_gameweek']
    },
    gameweek: {
        label: 'Gameweek',
        abbreviation: 'Gw',
        field: 'gameweek',
        description: 'The gameweek the fixture occurred in',
        sortable: true,
        models: ['player_fixture']
    },
    date: {
        label: 'Date',
        abbreviation: 'Date',
        field: 'date',
        description: 'The date the fixture occurred on',
        sortable: true,
        models: ['player_fixture']
    },
    opposition: {
        label: 'Opposition',
        abbreviation: 'Opp',
        field: 'opposition',
        description: 'The opposition for this fixture',
        sortable: true,
        models: ['player_fixture']
    },
    score: {
        label: 'Score',
        abbreviation: 'Score',
        field: 'score',
        description: 'The score line for this fixture',
        sortable: false,
        models: ['player_fixture']
    },
    season_name: {
        label: 'Season',
        abbreviation: 'Season',
        field: 'season_name',
        description: 'The season the stats are for',
        sortable: false,
        models: ['historical_stats']
    },
    points: {
        label: 'Points',
        abbreviation: 'Pts',
        field: 'points',
        description: 'How many points the player scored',
        sortable: true,
        suffix: ' Pts',
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek', 'historical_stats']
    },
    points_per_game: {
        label: 'Points Per Game',
        abbreviation: 'PPG',
        field: 'points_per_game',
        description: "Average points per game the players team played even if they didn't feature",
        sortable: true,
        models: ['player_season', 'player_gameweek']
    },
    points_per_appearance: {
        label: 'Points Per Appearance',
        abbreviation: 'PPA',
        field: 'points_per_appearance',
        description: 'Average points per appearance',
        sortable: true,
        models: ['player_season', 'player_gameweek']
    },
    points_per_ninety: {
        label: 'Points Per 90 Minutes',
        abbreviation: 'PP90',
        field: 'points_per_ninety',
        description: 'Average points per 90 minutes',
        sortable: true,
        models: ['player_season', 'player_gameweek']
    },
    points_per_start: {
        label: 'Points Per Start',
        abbreviation: 'PPSt',
        field: 'points_per_start',
        description: 'Average points per start',
        sortable: true,
        models: ['player_season', 'player_gameweek']
    },
    appearances: {
        label: 'Appearances',
        abbreviation: 'APP',
        field: 'appearances',
        description: 'Total number of appearances',
        sortable: true,
        models: ['player_season', 'player_gameweek', 'historical_stats']
    },
    starts: {
        label: 'Starts',
        abbreviation: 'St',
        field: 'starts',
        description: 'Total number of starts',
        sortable: true,
        models: ['player_season', 'player_gameweek', 'historical_stats']
    },
    started: {
        label: 'Started',
        abbreviation: 'STA',
        field: 'started',
        description: 'Whether the player started the game',
        sortable: true,
        models: []
    },
    minutes: {
        label: 'Minutes',
        abbreviation: 'MIN',
        field: 'minutes',
        description: 'Total minutes played',
        sortable: true,
        models: ['player_season', 'player_gameweek', 'historical_stats', 'player_fixture_form']
    },
    subbed_on_minute: {
        label: 'Minute Subbed On',
        abbreviation: 'ON',
        field: 'subbed_on_minute',
        description: 'Minute Subbed On',
        sortable: true,
        models: ['player_fixture_form']
    },
    subbed_off_minute: {
        label: 'Minuted Subbed Off',
        abbreviation: 'OFF',
        field: 'subbed_off_minute',
        description: 'Minute Subbed Off',
        sortable: true,
        models: ['player_fixture_form']
    },
    minutes_with_sub_icon: {
        label: 'Minutes',
        abbreviation: 'MIN',
        field: 'minutes',
        description: 'Total minutes played',
        sortable: true,
        models: ['player_fixture']
    },
    goals_scored: {
        label: 'Goals',
        abbreviation: 'GS',
        field: 'goals_scored',
        description: 'Total goals scored',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek', 'historical_stats']
    },
    assists: {
        label: 'Assists',
        abbreviation: 'A',
        field: 'assists',
        description: 'Total assists made',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek', 'historical_stats']
    },
    clean_sheets: {
        label: 'Clean Sheets',
        abbreviation: 'CS',
        field: 'clean_sheets',
        description: 'Total clean sheets',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek', 'historical_stats']
    },
    goals_conceded: {
        label: 'Goals Conceded',
        abbreviation: 'GC',
        field: 'goals_conceded',
        description: 'Total goals conceded',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek', 'historical_stats']
    },
    own_goals: {
        label: 'Own Goals',
        abbreviation: 'OG',
        field: 'own_goals',
        description: 'Total own goals scored',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek', 'historical_stats']
    },
    penalties_scored: {
        label: 'Penalties Scored',
        abbreviation: 'PGS',
        field: 'penalties_scored',
        description: 'Total penalties scored',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek', 'historical_stats']
    },
    penalties_missed: {
        label: 'Penalties Missed',
        abbreviation: 'PM',
        field: 'penalties_missed',
        description: 'Total penalties missed',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek', 'historical_stats']
    },
    penalties_won: {
        label: 'Penalties Won',
        abbreviation: 'PW',
        field: 'penalties_won',
        description: 'Total penalties won',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek', 'historical_stats']
    },
    penalties_conceded: {
        label: 'Penalties Conceded',
        abbreviation: 'PC',
        field: 'penalties_conceded',
        description: 'Total penalties conceded',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek', 'historical_stats']
    },
    penalties_savd: {
        label: 'Penalties Saved',
        abbreviation: 'PS',
        field: 'penalties_saved',
        description: 'Total penalties saved',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek', 'historical_stats']
    },
    saves: {
        label: 'Saves',
        abbreviation: 'S',
        field: 'saves',
        description: 'Total saves made',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek', 'historical_stats']
    },
    clearances_blocks_interceptions: {
        label: 'Clearances, Blocks & Interceptions',
        abbreviation: 'CBI',
        field: 'cbi',
        description: 'Total clearances, blocks, and interceptions',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek']
    },
    duels_won: {
        label: 'Duels Won',
        abbreviation: 'DW',
        field: 'duels_won',
        description: 'Total duels won',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek', 'historical_stats']
    },
    shots_on_target: {
        label: 'Shots on Target',
        abbreviation: 'SOT',
        field: 'shots_on_target',
        description: 'Total shots on target',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek', 'historical_stats']
    },
    key_passes: {
        label: 'Key Passes',
        abbreviation: 'KP',
        field: 'key_passes',
        description: 'Total key passes made',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek', 'historical_stats']
    },
    yellow_cards: {
        label: 'Yellow Cards',
        abbreviation: 'YC',
        field: 'yellow_cards',
        description: 'Total yellow cards received',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek', 'historical_stats']
    },
    red_cards: {
        label: 'Red Cards',
        abbreviation: 'RC',
        field: 'red_cards',
        description: 'Total red cards received',
        sortable: true,
        models: ['player_season', 'player_fixture', 'player_fixture_form', 'player_gameweek', 'historical_stats']
    },
}

// Add the method to filter by model using Lodash
columns.filterByModel = function(model, excludeFields = [], includeFields = []) {
    return _.filter(this, column => {
        return ((column.models || []).includes(model) || includeFields.includes(column.field))
            && !excludeFields.includes(column.field)
    });
}

export default columns;
