<template>
    <div id="player-list">
        <div class="p-3">
            <div id="player-list-filter" class="flex-default">
                <player-filter
                    v-model="filter"
                    :defaults="defaults"
                    :affordableValue="affordableValue"
                ></player-filter>
                <div class="pagination-controls">
                    <button @click="pageNum = previousPage" :disabled="!previousPage">Previous</button>
                    <button @click="pageNum = nextPage" :disabled="!nextPage">Next</button>
                </div>
            </div>

            <p v-if="showingPreviousStats" style="margin-top:1rem;">
                Stats are from previous rounds to help you pick. They will be updated with live stats once the tournament starts.
            </p>

            <p style="margin-top:1rem;">
                <template v-if="selectedPlayerIsPicked">
                    Click the name of a <strong>{{ selectedPlayer.position }}</strong> from the list below to swap <strong>{{ selectedPlayer.name }}</strong> with that player
                </template>
                <template v-else-if="selectedPlayer">
                    Click <i class="fa-solid fa-arrow-right-arrow-left"></i> on an <strong>{{ selectedPlayer.position }}</strong> on the pitch to swap <strong>{{ selectedPlayer.name }}</strong> with that player
                </template>
                <template v-else-if="true">
                    Click a players name to select them for a transfer.
                </template>
                <!--
                <router-link
                    :to="{name:'player-histories'}"
                    target="_blank">
                    <button class="btn">
                        <i class="fa-solid fa-info"></i>
                    </button> View all historical stats
                </router-link>
                -->
            </p>
        </div>
        <div class="player-list-table">
            <table>
                <player-list-headers
                    :show-value="showValue"
                    :filter="filter"
                    @selectColumn="selectColumn($event)"
                    :columns="columns"
                ></player-list-headers>
                <tbody>
                    <player-list-row
                        v-for="player in paginatedPlayers"
                        :key="player.id"
                        :player="player"
                        :stats="player"
                        :is-selected="player.id === selectedPlayerId"
                        :is-picked="picks && picks.includes(player.id)"
                        :fade="selectedPlayerIsPicked && selectedPlayer.position !== player.position"
                        :show-value="showValue"
                        :columns="columns"
                        @selectPlayer="$emit('selectPlayer', player.id)">
                    </player-list-row>
                </tbody>
            </table>
        </div>
        <div class="flex-default mt-3">
            <p>Page {{ pageNum }} of {{ totalPages }}</p>
            <div class="pagination-controls">
                <button @click="pageNum = previousPage" :disabled="!previousPage">Previous</button>
                <button @click="pageNum = nextPage" :disabled="!nextPage">Next</button>
            </div>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, ref, watch} from "vue";
import PlayerListRow from "./PlayerListRow.vue";
import PlayerFilter from "./PlayerFilter.vue";
import PlayerListHeaders from "./PlayerListHeaders.vue";
import playerColumns from "@/config/PlayerColumns";

export default {
    components: {PlayerListHeaders, PlayerFilter, PlayerListRow},
    props: {
        selectedPlayerId: {
            type: Number,
            default: undefined
        },
        picks: {
            type: Array,
            default: () => []
        },
        defaults: {
            type: Object,
            default: () => {}
        },
        affordableValue: {
            type: Number,
            default: undefined
        }
    },
    emits: ['selectPlayer'],
    setup(props) {
        const store = useSeasonStore()

        const filter = ref({
            team_id: 'ALL',
            value: 'ALL',
            position: 'ALL',
            sort: store.season.budget !== null ? 'value' : 'points_per_game',
            sort_dir: 'desc',
            status: 'active',
            ...props.defaults
        })

        watch(filter, () => {
            pageNum.value = 1
        })

        const pageNum = ref(1)

        const perPage = 50;

        const columns = playerColumns.filterByModel('player_season')

        const showValue = computed(() => {
            return store.season.budget !== null
        })

        const showingPreviousStats = computed(() => {
            return !!store.usePreviousSeasonId
        })

        const start = computed(() => {
            return (pageNum.value * perPage) - perPage
        })

        const end = computed(() => {
            return (pageNum.value * perPage)
        })

        const totalPages = computed(() => {
            return _.ceil(_.size(filteredPlayers.value) / perPage)
        })

        const filteredPlayers = computed(() => {
            return store.filterPlayers(filter.value)
        })

        const paginatedPlayers = computed(() => {
            return _.slice(filteredPlayers.value, start.value, end.value)
        })

        const selectedPlayer = computed(() => {
            return store.players[props.selectedPlayerId]
        })

        const selectedPlayerIsPicked = computed(() => {
            return selectedPlayer.value && props.selectedPlayerId && props.picks.includes(props.selectedPlayerId)
        })

        const previousPage = computed(() => {
            return pageNum.value > 1 ? pageNum.value - 1 : null
        })

        const nextPage = computed(() => {
            return pageNum.value < totalPages.value ? pageNum.value + 1 : null
        })

        const selectColumn = function(column) {
            if (filter.value.sort === column.field) {
                filter.value.sort_dir = filter.value.sort_dir === 'asc' ? 'desc' : 'asc'
            } else {
                filter.value.sort = column.field
                filter.value.sort_dir = 'desc'
            }
        }

        return {columns, showValue, showingPreviousStats, paginatedPlayers, selectedPlayer, selectedPlayerIsPicked, totalPages, pageNum, previousPage, nextPage, filter, selectColumn}
    }
}
</script>
