<template>
    <modal v-if="showInfoModal" @close="showInfoModal = false">
        <template #body>
            <div id="chips-modal">
                <explanation-chips class="p-3"></explanation-chips>
            </div>
        </template>
    </modal>
    <div v-if="hasChips" class="chip-input">
        <label for="chip-input">
            Active Chip:
            <a href="#" @click.prevent="showInfoModal = true" class="text-sm font-thin">
                (Explanation?)
            </a>
        </label>

        <select :value="seasonChipValue" @change="updateSeasonChipId($event.target.value)" id="chip-input">
            <option :value="0">No Chip</option>
            <option :value="chip.id" v-for="chip in chips" :disabled="chip.disabled">
                {{ chip.title }}
                <template v-if="authChips.hasOwnProperty(chip.chip_id) && authChips[chip.id] !== gameweek.number">
                    (Used GW {{ authChips[chip.id] }})
                </template>
                <template v-else-if="chip.available_text">
                    ({{ chip.available_text }})
                </template>
            </option>
        </select>

        <div v-if="activeChip && activeChip.has_options" class="flex mt-3 chip-option-buttons items-center">
            <div>
                Choose:
            </div>
            <button
                v-for="chipOption in activeChip.options"
                @click="updateChipOptionId(chipOption.id)"
                :class="{selected: chipOptionId === chipOption.id}"
            >
                {{ chipOption.title }}
            </button>
        </div>
    </div>
</template>
<script>
import {computed, ref, watch} from "vue";
import {useSeasonStore} from "@/stores/seasonStore";
import PlayerProfile from "@/components/PlayerProfile.vue";
import Modal from "@/components/Modal.vue";
import ExplanationChips from "@/components/ExplanationChips.vue";

export default {
    components: {ExplanationChips, Modal, PlayerProfile},
    emits: ['update:seasonChipId', 'update:chipOptionId'],
    props: {
        seasonChipId: {
            type: Number,
            required: false
        },
        chipOptionId: {
            type: String,
            required: false
        },
        gameweek: {
            type: Object,
            required: true
        }
    },
    setup(props, { emit }) {
        const seasonStore = useSeasonStore()

        const showInfoModal = ref(false)

        const chips = computed(() => {
            return _.keyBy(_.map(seasonStore.chips, chip => {
                return {
                    ...chip,
                    disabled: (chip.start_gameweek_number && chip.start_gameweek_number > props.gameweek.number)
                        || (chip.end_gameweek_number && chip.end_gameweek_number < props.gameweek.number)
                        || (authChips.value.hasOwnProperty(chip.id) && authChips.value[chip.id] !== props.gameweek.number)
                }
            }), 'id')
        })

        const hasChips = computed(() => {
            return !_.isEmpty(chips.value)
        })

        const authChips = computed(() => {
            return _.pluck(seasonStore.authChips, 'gameweek_number', 'season_chip_id')
        })

        const activeChip = computed(() => {
            return props.seasonChipId
                ? chips.value[props.seasonChipId]
                : null
        })

        const seasonChipValue = computed(() => {
            // No idea why this is needed, but the null select wasn't being chosen by default
            return Number(props.seasonChipId)
        })

        const updateSeasonChipId = (value) => {
            if (!value || value === 'null') {
                emit('update:seasonChipId', null);
                emit('update:chipOptionId', null);
                return;
            }

            const newChip = chips.value[value]

            if (!newChip) {
                emit('update:seasonChipId', null);
                emit('update:chipOptionId', null);
                return;
            }

            emit('update:seasonChipId', parseInt(value));

            if (!newChip.has_options) {
                emit('update:chipOptionId', null);
            } else if(!_.map(newChip.options, 'id').includes(props.chipOptionId)) {
                emit('update:chipOptionId', newChip.options[0].id);
            }
        };

        const updateChipOptionId = (value) => {
            emit('update:chipOptionId', value);
        };

        return {
            seasonChipValue,
            showInfoModal,
            hasChips,
            chips,
            authChips,
            activeChip,
            updateSeasonChipId,
            updateChipOptionId
        }
    }
}
</script>
