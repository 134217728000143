<template>
    <div v-if="loaded" class="grid grid-cols-1 lg:grid-cols-2">
        <div class="box blue-gradient">
            <template v-if="playerSeasons.length > 0">
                <h1>{{ playerSeasons.length }} Pending Players</h1>
                <admin-player-table
                    :players="playerSeasons"
                    :columns="playerColumns"
                ></admin-player-table>
            </template>
            <p v-else>No players pending moderation.</p>
        </div>
        <div class="box blue-gradient">
            <template v-if="activeDuplicates.length > 0">
                <h1>{{ activeDuplicates.length }} Active Duplicates</h1>
                <admin-player-table
                    :players="activeDuplicates"
                    :columns="playerColumns"
                ></admin-player-table>
            </template>
            <p v-else>No duplicate players have activity.</p>
            <h1>{{ pendingTransfersCount }} Pending Transfers</h1>
            <div class="stats-table">
                <table class="w-full">
                    <thead>
                        <tr>
                            <th>Season</th>
                            <th>Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(count, seasonId) in pendingTransfers">
                            <tr v-if="seasons.hasOwnProperty(seasonId)">
                                <td>
                                    <router-link :to="{name:'mod-transfers', params: {seasonId: seasonId, status: 'pending'}}" class="hover-underline">
                                        {{ seasons[seasonId].name }}
                                    </router-link>
                                </td>
                                <td>
                                    {{ count }}
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="box blue-gradient">
            <h1>Invalid Gameweeks</h1>
            <div
                v-if="gameweeks"
            >
                <div v-for="gameweek in gameweeks">
                    {{ seasons.hasOwnProperty(gameweek.season_id) ? seasons[gameweek.season_id].name : 'Season #' + gameweek.season_id }}
                    - GW {{ gameweek.number }}
                    - {{ gameweek.total_days }} Days
                    - {{ gameweek.fixtures_count }} Fixtures
                    - {{ gameweek.invalid_fixtures_count }} Invalid Fixtures
                    <template v-if="gameweek.auto_sub_error">
                        - Cannot process auto subs
                    </template>
                </div>
            </div>
            <p v-else>No gameweeks are invalid.</p>
        </div>
        <div class="box blue-gradient">
            <h1>Pending Fixtures</h1>
            <div
                v-if="fixtures"
            >
                <div v-for="fixture in fixtures">
                    {{ fixture.description }}
                </div>
            </div>
            <p v-else>No fixtures pending moderation.</p>
        </div>
    </div>
</template>

<script>

import {computed, onMounted, ref} from "vue";
import {useDefaultStore} from "@/stores/defaultStore";
import AdminPlayerTable from "@/components/AdminPlayerTable.vue";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {AdminPlayerTable},
    setup() {
        useSeoMeta({
            title: 'God Dashboard',
            description: 'God Dashboard'
        })
        const defaultStore = useDefaultStore()

        const playerColumns = [
            'season',
            'name',
            'team',
            'age',
            'position',
            'player_id',
            'player_season_id',
            'status',
            'mod_status',
            'selected_by',
            'value',
            'points',
            'urls',
            'created_at',
        ];

        const seasons = computed(() => defaultStore.seasons)
        const loaded = ref(false)
        const fixtures = ref(undefined);
        const playerSeasons = ref(undefined);
        const gameweeks = ref(undefined);
        const activeDuplicates = ref(undefined);
        const pendingTransfers = ref(undefined);

        const pendingTransfersCount = computed(() => {
            return _.sum(Object.values(pendingTransfers.value))
        })

        onMounted(() => {
            axios
                .get('/api/admin/pending')
                .then(response => {
                    fixtures.value = response.data.fixtures
                    playerSeasons.value = response.data.player_seasons
                    gameweeks.value = response.data.gameweeks
                    activeDuplicates.value = response.data.active_duplicates
                    pendingTransfers.value = response.data.pending_transfers
                    loaded.value = true
                })
        })

        return {playerColumns, loaded, seasons, fixtures, playerSeasons, gameweeks, activeDuplicates, pendingTransfers, pendingTransfersCount}
    }
}
</script>
