<template>
    <div v-if="season.status === 'active'" id="entry-form" class="form box">
        <h1>Create Your Team</h1>
        <div class="paragraphs mb-3">
            <p>To setup your team, first please give it a name.</p>
            <p>People often create witty or funny names, but it doesn't matter "John's Team" is perfectly acceptable team.</p>
            <p>Don't worry you can always change your team name later.</p>
        </div>
        <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="register_team_name">Team Name</label>
                <input type="text" id="register_team_name"
                       placeholder="Team Name"
                       required
                       maxlength="32"
                       v-model="teamName">
            </div>
            <div class="mb-3">
                <button>
                    Create Team
                </button>
            </div>
        </form>
        <p>Here's some example names you might enjoy.</p>
        <ul class="list-disc ml-5">
            <li>It's Getting Messi</li>
            <li>Only Kruls & Horses</li>
            <li>Hakuna Juan Mata</li>
            <li>Top Of The Klopps</li>
            <li>Finding Timo</li>
        </ul>
    </div>
    <div v-else class="error box">
        <p>Sorry it isn't possible to create a team for this season.</p>
    </div>
</template>
<script>
import {computed, ref} from "vue";
import {useSeasonStore} from "../stores/seasonStore";
import {useFlashStore} from "@/stores/flashStore";

export default {
    emits: ['success', 'error'],
    setup(props, {emit}) {
        const store = useSeasonStore()

        const teamName = ref('')

        const submitForm = () => {
            store.createEntry(teamName.value)
                .then(response => {
                    useFlashStore().setFlash('success', 'Team Created!')
                    emit('success', response)
                })
                .catch(error =>  {
                    emit('error', error)
                    useFlashStore().axiosError(error, 'Failed to create team')
                })
        }

        const season = computed(() => {
            return store.season
        })

        return {season, teamName, submitForm}
    }
}
</script>
