<template>
    <div>
        <div class="paragraphs">
            <h1 style="font-size:3rem">How does it work?</h1>
            <p>In Fantasy Football you pick a team of players that are competing in real life. You can pick 2 goalkeepers, 5 defenders, 5 midfielders and 3 strikers.</p>
            <p>You score points if the players you picked play well in real life e.g. scoring goals, keeping clean sheets.</p>
            <p>Although you pick 15 players, only 11 start, you have 4 substitutes. You must pick two of your starters as either a captain or a vice captain.</p>
            <p>You must play at least 1 GK 3 DEF, 3 MID and 1 ST. Outside of this you can use any formation you please.</p>
            <subs-text></subs-text>
            <!--
            <p>You may only have 3 players per team up until the quarter final.</p>
            <p>You may only have 6 players per team up until the semi final.</p>
            <p>You may have unlimited players per team in the final.</p>
            -->
            <h1 style="font-size:3rem">Deadlines & Transfers</h1>
            <p>Before each gameweek there are deadlines. The deadline is always 30 minutes before the first match of that Gameweek.</p>
            <p>Any changes you make after this will only affect the next deadline.</p>
            <template v-if="season">
                <template v-if="season.free_transfers > 0">
                    <p>You can make {{ season.free_transfers }} free transfers between each round.</p>
                    <p>Each additional transfer will cost you {{ season.cost_per_transfer }} points.</p>
                    <template v-if="season.max_free_transfers > season.free_transfers">
                        <p>{{ season.rollover_free_transfers }} transfer(s) roll over each week allowing you to keep a maximum of {{ season.max_free_transfers }} free transfers, at which point they won't roll over anymore.</p>
                        <template v-if="season.rollover_free_transfers === 1 && season.free_transfers === 2">
                            <ul style="list-style: circle;margin-left:2rem;margin-bottom: 1rem;">
                                <li>If you have 0 remaining = You get 2 next week</li>
                                <li>If you have 1 remaining = You get 3 next week</li>
                                <li>If you have 2 remaining = You get 3 next week</li>
                                <li>If you have 3 remaining = You get 4 next week</li>
                                <li>If you have 4 remaining = You get 5 next week</li>
                            </ul>
                        </template>
                    </template>
                    <template v-else>
                        <p>Your transfers do not roll over to the following gameweek. If you don't use them then you lose them.</p>
                    </template>
                    <p>This is only valid after GW1, before the very first match of the season you can make unlimited transfers on your initial squad.</p>

                </template>
                <template v-else>
                    <p>You can make unlimited free transfers between each round.</p>
                </template>
            </template>

            <h1 style="font-size:3rem">How does scoring work?</h1>
            <p>You score points in the following ways.</p>
            <ul style="list-style: circle;margin-left:2rem;">
                <li>
                    1 point for playing any minutes in a match.
                </li>
                <li>
                    1 further point for playing 60 minutes or more in a match.
                </li>
                <li>
                    3 points for each penalty goal scored by any player
                </li>
                <li>
                    -2 for every penalty miss
                </li>
                <li>
                    10 points for each non penalty goal scored by a GK
                </li>
                <li>
                    6 points for each non penalty goal scored by a DEF
                </li>
                <li>
                    5 points for each non penalty goal scored by a MID
                </li>
                <li>
                    4 points for each non penalty goal scored by a ATT
                </li>
                <li>
                    1 point for every penalty won
                </li>
                <li>
                    3 points for each assist for a goal
                </li>
                <li>
                    There are NO Fantasy Assists
                </li>
                <template v-if="season.scoring_type === 'simple'">
                    <li>
                        4 points for a clean sheet by a GK or DEF playing 60 or more minutes
                    </li>
                </template>
                <template v-else>
                    <li>
                        5 points for a clean sheet by a GK playing 60 or more minutes
                    </li>
                    <li>
                        4 points for a clean sheet by a DEF playing 60 or more minutes
                    </li>
                </template>
                <li>
                    1 point for a clean sheet by a MID playing 60 or more minutes
                </li>
                <li v-if="season.scoring_type === 'simple'">
                    1 point for every 3 shots saved by a GK
                </li>
                <li v-else-if="season.scoring_type === 'advanced'">
                    1 point for every 2 shots saved by a GK
                </li>
                <li>
                    5 points for every penalty save not including penalty shoot outs
                </li>
                <li>
                    -1 point for every penalty conceded
                </li>
                <li>
                    -1 points for every 2 goals conceded by a GK or DEF not subbed off before the goals were scored
                </li>
                <li>
                    Players shown a red card continue to score minus points for goals conceded after being sent off.
                </li>
                <li>
                    -1 points for every yellow card
                </li>
                <li>
                    -3 points for every red card
                </li>
                <li>
                    -2 points for every own goal
                </li>
                <template v-if="season.scoring_type === 'advanced'">
                    <li>
                        1 point for every 3 clearances, blocks and interceptions combined for GK and ATT
                    </li>
                    <li>
                        1 point for every 4 clearances, blocks and interceptions combined for MID
                    </li>
                    <li>
                        1 point for every 5 clearances, blocks and interceptions combined for DEF
                    </li>
                    <li>
                        1 point for every 5 duels won
                    </li>
                    <li>
                        1 point for every 2 shots on target
                    </li>
                    <li>
                        1 point for every 2 key passes
                    </li>
                </template>
            </ul>
            <explanation-chips></explanation-chips>
        </div>
        <explanation-price></explanation-price>
        <br /><br />
    </div>
</template>
<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";
import ExplanationPrice from "./ExplanationPrice.vue";
import SubsText from "@/components/SubsText.vue";
import ExplanationChips from "@/components/ExplanationChips.vue";
export default {
    components: {ExplanationChips, SubsText, ExplanationPrice},
    setup() {
        const store = useSeasonStore()

        const season = computed(() => {
            return store.season
        })

        return {season}
    }
}
</script>
