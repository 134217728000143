<template>
    <div class="square" :class="{selected: isSelected}">
        <div v-if="isSub" class="sub-position-label">
            {{ player.position }}
        </div>
        <div type="button" class="player">
            <img class="shirt" :src="imagePath" />
            <div class="details">
                <div v-if="isCaptain" class="captain-icon">
                    <i class="fa-solid fa-star"></i>
                </div>
                <div v-if="isViceCaptain" class="vice-captain-icon">
                    <i class="fa-solid fa-star"></i>
                </div>
                <a class="name" :title="player.name">
                    {{ player.display_name }}
                </a>
                <div v-if="player.status !== 'active' && ['sale_value', 'value', 'opposition', 'team'].includes(infoType)" class="opposition" style="background:red" title="This player is not in the official squad and will not play.">
                    <span>Inactive<template v-if="player.value > 0"> $ {{ player.pretty_value }}</template></span>
                </div>
                <div v-else class="opposition">
                    <span v-if="infoType === 'team'">{{ team.short_name }}</span>
                    <span v-if="infoType === 'value' || (infoType === 'sale_value' && !saleValue)">$ {{ player.pretty_value }}</span>
                    <span v-if="infoType === 'sale_value' && saleValue">$ {{ (saleValue / 10).toFixed(1) }}</span>
                    <span v-if="infoType === 'opposition'">
                        <div v-for="opponent in opposition">
                            {{ opponent.team.short_name }}
                            ({{ opponent.location }})
                        </div>
                    </span>
                    <span v-if="infoType === 'points'">
                        {{ team.short_name }} - {{ points }}
                    </span>
                </div>
            </div>
            <div class="mobile-click-area" @click="onSelect">

            </div>
                <div v-if="selectable" class="switch-player" @click="onSelect">
                    <button>
                        <i class="fa-solid fa-arrow-right-arrow-left"></i>
                    </button>
                </div>
                <div v-else-if="subbedOnOff" class="subbed-on-off">
                    <button>
                        <i v-if="isSub" class="fa-solid fa-arrow-up text-green-500" title="Subbed On"></i>
                        <i v-else class="fa-solid fa-arrow-down text-red-500" title="Subbed Off"></i>
                    </button>
                </div>
                <div v-else>

                </div>
                <div class="show-info">
                    <button @click="showModal">
                        <i class="fa-solid fa-info"></i>
                    </button>
                </div>
        </div>
    </div>
</template>

<script>

import {computed, ref} from "vue";
import PlayerProfile from "./PlayerProfile.vue";
import Modal from './Modal.vue'
import {useSeasonStore} from "../stores/seasonStore";

export default {
    components: {PlayerProfile, Modal},
    emits: ['selected'],
    props: {
        player: {
            type: Object,
            required: true
        },
        stats: {
            type: Object,
            required: false
        },
        selectable: {
            type: Boolean,
            default: true
        },
        isSelected: {
            type: Boolean,
            default: false
        },
        isCaptain: {
            type: Boolean,
            required: false
        },
        isViceCaptain: {
            type: Boolean,
            required: false
        },
        multiplier: {
            type: Number,
            default: 1
        },
        isSub: {
            type: Boolean,
            required: false
        },
        subbedOnOff: {
            type: Boolean,
            required: false
        },
        infoType: {
            type: String,
            default: 'value'
        },
        saleValue: {
            type: Number,
            required: false
        }
    },
    setup(props, {emit}) {
        const store = useSeasonStore()

        const onSelect = () => {
            if (props.selectable) {
                emit('selected', props.player.id)
            } else {
                showModal()
            }
        }

        const imagePath = computed(() => {
            return team.value.image_path
        })

        const showModal = function() {
            store.modalPlayerId = props.player.id
        }

        const team = computed(() => {
            return store.teams[props.player.team_id]
        })

        const opposition = computed(() => {
            return store.getGwOpposition(props.player.team_id, store.transfersGwId)
        })

        const points = computed(() => {
            return props.stats ? (props.stats.points * props.multiplier) : 'NOPGW'
        })

        return {onSelect, imagePath, showModal, team, opposition, points}
    }
}
</script>
