<template>
    <div v-if="!authEntry" class="p-3">
        <p>You must create a team before you can join a mini league.</p>
        <div>
            <router-link :to="{name: 'transfers'}" class="call-to-action">
                Create Team
            </router-link>
        </div>
    </div>
    <div v-else id="mini-leagues-page" class="grid grid-cols-1 lg:grid-cols-2">
        <div>
            <join-league-form @success="addLeague"></join-league-form>
            <create-league-form @success="addLeague"></create-league-form>
        </div>
        <div>
            <mini-leagues-table :leagues="leagues" class="box"></mini-leagues-table>
        </div>
    </div>
</template>

<script>
import {onMounted, ref, computed} from "vue";
import {useSeasonStore} from "../stores/seasonStore.js";
import JoinLeagueForm from "../components/JoinLeagueForm.vue";
import CreateLeagueForm from "../components/CreateLeagueForm.vue";
import miniLeagueQuery from "../queries/miniLeagueQuery.js";
import {useSeoMeta} from "@unhead/vue";
import MiniLeaguesTable from "@/components/MiniLeaguesTable.vue";
export default {
    components: {MiniLeaguesTable, CreateLeagueForm, JoinLeagueForm},
    setup() {
        const store = useSeasonStore()

        useSeoMeta({
            title: () => 'Mini Leagues | Fantasy ' + store.season.name,
            description: () => 'Use mini league to play ' + store.season.name + ' against your friends.'
        })

        onMounted(() => {
            if (store.authEntry) {
                miniLeagueQuery
                    .getEntries(store.authEntry)
                    .then(response => {
                        leagues.value = response.mini_leagues
                    })
            }
        })

        const authEntry = computed(() => {
            return store.authEntry
        })

        const addLeague = (miniLeague) => {
            console.log(miniLeague)
            leagues.value.push(miniLeague)
        }

        const leagues = ref([])

        return {authEntry, addLeague, leagues}
    }
}
</script>
