<template>
    <tr :class="{selected: isSelected, picked: isPicked, fade: fade}">
        <template v-for="(column, columnKey) in columns">
            <td v-if="column.field === 'info_icon'">
                <button @click="showModal" style="margin:0;" class="btn">
                    <i class="fa-solid fa-info"></i>
                </button>
            </td>
            <template v-else-if="column.field === 'name'" >
                <th v-if="routeName" class="name with-link" :title="player.name">
                    <router-link :to="{name: routeName, params: {playerId: player.id}}">
                        <span class="hidden md:inline">{{ player.name }}</span>
                        <span class="inline md:hidden" :data-customTooltip="player.name">{{ player.display_name }}</span>
                        <template v-if="pick">
                            <i v-if="pick.is_captain" class="fa-solid fa-star ml-1 captain-icon" title="Captain"></i>
                            <i v-else-if="pick.is_vice_captain" class="fa-solid fa-star ml-1 vice-captain-icon" title="Vice Captain"></i>
                            <i v-if="pick.is_sub && pick.sub_id" class="fa-solid fa-arrow-up ml-1 text-green-500" title="Subbed On"></i>
                            <i v-else-if="pick.is_starter && pick.sub_id" class="fa-solid fa-arrow-down ml-1 text-red-500" title="Subbed Off"></i>
                        </template>
                    </router-link>
                </th>
                <th v-else class="name without-link" @click="$emit('selectPlayer')" :title="player.name">
                    <span class="hidden md:inline">{{ player.name }}</span>
                    <span class="inline md:hidden" :data-customTooltip="player.name">{{ player.display_name }}</span>
                    <template v-if="pick">
                        <i v-if="pick.is_captain" class="fa-solid fa-star ml-1 captain-icon" title="Captain"></i>
                        <i v-else-if="pick.is_vice_captain" class="fa-solid fa-star ml-1 vice-captain-icon" title="Vice Captain"></i>
                        <i v-if="pick.is_sub && pick.sub_id" class="fa-solid fa-arrow-up ml-1 text-green-500" title="Subbed On"></i>
                        <i v-else-if="pick.is_starter && pick.sub_id" class="fa-solid fa-arrow-down ml-1 text-red-500" title="Subbed Off"></i>
                    </template>
                </th>
            </template>
            <template v-else-if="column.field === 'value'">
                <td v-if="showValue">
                    ${{ player.pretty_value }}
                </td>
            </template>
            <td v-else-if="column.field === 'position'">
                {{ player.position }}
            </td>
            <td v-else-if="column.field === 'team'">
                <div v-if="team" class="team">
                    <div class="logo">
                        <img :src="team.image_path" width="25" height="25" />
                    </div>
                    {{ team.short_name }}
                </div>
            </td>
            <td v-else-if="column.field === 'selected_by_percent'">
                {{ formattedSelectedBy }}%
            </td>
            <td v-else-if="column.field === 'started'">
                <i v-if="stats.started" class="fa-solid fa-check"></i>
                <i v-else class="fa-solid fa-times"></i>
            </td>
            <td v-else-if="columnKey === 'minutes_with_sub_icon'">
                {{ (stats[column.field] || 0) }}
                <i v-if="stats.subbed_on_minute" class="fa-solid fa-arrow-up ml-1 text-green-500" :title="'Subbed On ' + stats.subbed_on_minute"></i>
                <i v-else-if="stats.subbed_off_minute" class="fa-solid fa-arrow-down ml-1 text-red-500" :title="'Subbed Off ' + stats.subbed_off_minute"></i>
                <i v-else class="fa-solid fa-arrow-up ml-1 text-green-500 invisible"></i>
            </td>
            <td v-else-if="column.field === 'points'">
                <template v-if="pick">
                    {{ stats.points * pick.multiplier }}
                    <small>({{ stats.points }})</small>
                </template>
                <template v-else>
                    {{ stats[column.field] || 0 }}
                </template>
            </td>
            <td v-else-if="column.field === 'gameweek'">
                GW {{ gameweek.number }}
            </td>
            <td v-else-if="column.field === 'opposition'">
                <div v-if="opposition" class="team">
                    <div class="logo">
                        <img :src="opposition.image_path" width="25" height="25" />
                    </div>
                    {{ opposition.short_name }}
                    <template v-if="stats.venue">
                        ({{ stats.venue }})
                    </template>
                </div>
                <span v-else>
                    {{ stats.opposition_id }}
                </span>
            </td>
            <td v-else>
                {{ (stats[column.field] || 0) }} {{ column.suffix }}
            </td>
        </template>
    </tr>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, ref} from "vue";
export default {
    emits: ['selectPlayer'],
    props: {
        routeName: {
            type: String,
            default: null
        },
        player: {
            type: Object,
            required: true
        },
        stats: {
            type: Object,
            required: true
        },
        pick: {
            type: Object,
            required: false
        },
        isSelected: {
            type: Boolean,
            default: false
        },
        isPicked: {
            type: Boolean,
            default: false
        },
        fade: {
            type: Boolean,
            default: false
        },
        showValue: {
            type: Boolean,
            default: true
        },
        filter: {
            type: Object,
            required: false
        },
        columns: {
            type: Object,
            required: true
        }
    },
    setup(props, {emit}) {
        const store = useSeasonStore()

        const team = computed(() => {
            return store.teams[props.stats.team_id]
        })

        const opposition = computed(() => {
            return store.teams[props.stats.opposition_id]
        })

        const formattedSelectedBy = computed(() => {
            return (props.player.selected_by_percent || 0).toFixed(0)
        })

        const gameweek = computed(() => {
            if (props.stats.gameweek_id) {
                return store.gameweeks[stats.value.gameweek_id]
            }

            return null
        })

        const activeField = computed(() => {
            return _.get(props.filter, 'sort')
        })

        const showModal = function() {
            store.modalPlayerId = props.player.id
        }

        return {team, opposition, formattedSelectedBy, gameweek, activeField, showModal}
    }
}
</script>
