<template>
    <div id="login-form" class="form box">
        <h1>Login</h1>
        <form @submit.prevent="submitLoginForm">
            <div class="mb-3">
                <label for="login_email">Your email</label>
                <input type="email" id="login_email"
                       class=""
                       placeholder="" required
                        v-model="email">
            </div>
            <div class="mb-3">
                <label for="login_password">Your password</label>
                <input type="password" id="login_password"
                       min="6"
                       required
                       v-model="password">
            </div>
            <div class="mb-3">
                <button>
                    Login
                </button>
            </div>
        </form>
    </div>
</template>
<script>
import {computed, ref} from "vue";
import FormButton from "../elements/FormButton.vue";
import {useDefaultStore} from "../stores/defaultStore.js";
import {useFlashStore} from "@/stores/flashStore";

export default {
    components: {FormButton},
    emits: ['authenticated', 'error'],
    setup(props, {emit}) {
        const store = useDefaultStore()

        const email = ref('')
        const password = ref('')

        const submitLoginForm = () => {
            store.login(email.value, password.value)
                .then(response => {
                    useFlashStore().setFlash('success', 'You are now logged in')
                    emit('authenticated')
                })
                .catch(error =>  {
                    useFlashStore().axiosError(error, 'Error Logging In')
                    emit('error', error)
                })
        }

        return {email, password, submitLoginForm}
    }
}
</script>
