<template>
    <div v-if="loaded && activeGw" id="transfer-stats" class="box">
        <div class="flex-default">
            <h2>GW {{ activeGw.number }} Transfer Stats</h2>
            <gameweek-pagination
                :active-gw="activeGw.id"
                :max-number="maxGwNumber"
                @goTo="activeGwId = $event"
            ></gameweek-pagination>
        </div>
        <div v-if="transferStats.hasOwnProperty(activeGwId)" :key="activeGwId" class="grid grid-cols-1 md:grid-cols-2">
            <div class="transfers-in">
                <div class="mr-3">
                    <h3>In</h3>
                    <table class="w-full">
                        <thead>
                            <tr>
                                <th>
                                    Player
                                </th>
                                <!--
                                <th>
                                    In
                                </th>
                                -->
                                <th>
                                    % In
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <transfer-stats-row
                                v-for="playerGameweek in transferStats[activeGwId].in"
                                :player-gameweek="playerGameweek"
                                :show-out="false"
                            ></transfer-stats-row>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="transfers-out">
                <div class="ml-3">
                    <h3>Out</h3>
                    <table class="w-full">
                        <thead>
                        <tr>
                            <th>
                                Player
                            </th>
                            <!--
                            <th>
                                Out
                            </th>
                            -->
                            <th>
                                % Out
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <transfer-stats-row
                            v-for="playerGameweek in transferStats[activeGwId].out"
                            :player-gameweek="playerGameweek"
                            :show-in="false"
                        ></transfer-stats-row>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, onMounted, ref} from "vue";
import PickerPlayer from "./PickerPlayer.vue";
import GameweekPagination from "./GameweekPagination.vue";
import TransferStatsRow from "./TransferStatsRow.vue";

export default {
    components: {TransferStatsRow, GameweekPagination, PickerPlayer},
    props: {
        gameweekId: {
            type: [Number,String],
            default: undefined
        }
    },
    setup(props) {
        const store = useSeasonStore()

        onMounted(() => {
            axios
                .get('/api/transfer-stats', {
                    params: {
                        season_id: store.seasonId
                    }
                })
                .then(response => {
                    transferStats.value = response.data
                })
        })

        const maxGwNumber = computed(() => store.transfersGameweek ? store.transfersGameweek.number : _.maxBy(store.gameweeks, 'number'))
        const activeGwId = ref(props.gameweek_id || store.transfersGwId)
        const activeGw = computed(() => store.gameweeks[activeGwId.value])
        const transferStats = ref({})
        const players = computed(() => store.players)
        const loaded = computed(() => {
            return Object.keys(transferStats.value).length > 0
        })

        return {loaded, maxGwNumber, activeGwId, activeGw, players, transferStats}
    }
}
</script>
