<template>
    <div v-if="!authEntry || authEntry.status !== 'valid'">
        <div class="error box">
            You need to <router-link :to="{name:'transfers'}">pick your squad first</router-link>
        </div>
    </div>
    <div v-else-if="!gameweekIsOpen">
        <gameweek-locked-box></gameweek-locked-box>
    </div>
    <div v-else-if="!isValidSquadSize">
        <div class="error box">
            Your team does not appear to be valid. Either <router-link :to="{name:'transfers'}">pick your squad first</router-link> or try refreshing the page.
        </div>
    </div>
    <template v-else-if="loaded">
        <div class="flex flex-col lg:flex-row">
            <team-picker></team-picker>
            <div>
                <div id="picker-form" class="form box">
                    <router-link :to="{name: 'season-settings'}" class="flex items-center">
                        <h3>{{ authEntry.name }}</h3>
                        <i class="ml-3 fa-solid fa-pen-to-square"></i>
                    </router-link>
                    <p>Use the pitch view on the left to pick your team. Once you're ready hit the save button below!</p>
                    <div class="captain-input">
                        <label for="captain-input">Captain:</label>

                        <select v-model="teamPickerStore.captainId" id="captain-input">
                            <option :value="null">Pick a Captain</option>
                            <option :value="starter.id" v-for="starter in starters">
                                {{ starter.name }}
                            </option>
                        </select>
                    </div>

                    <div class="vice-captain-input">
                        <label for="vice-captain-input">Vice Captain:</label>

                        <select v-model="teamPickerStore.viceCaptainId" id="vice-captain-input">
                            <option :value="null">Pick a Vice Captain</option>
                            <option :value="starter.id" v-for="starter in starters">
                                {{ starter.name }}
                            </option>
                        </select>
                    </div>

                    <chip-input
                        v-model:seasonChipId="teamPickerStore.seasonChipId"
                        v-model:chipOptionId="teamPickerStore.chipOptionId"
                        :gameweek="activeGameweek"
                    ></chip-input>

                    <div style="padding:1rem 0;">
                        <button v-if="!hasChanged" :disabled="true">
                            Team Already Saved
                        </button>
                        <button v-else-if="hasValidationErrors" :disabled="true">
                            Team Invalid
                        </button>
                        <button v-else-if="saving" :disabled="true">
                            Saving...
                        </button>
                        <button v-else @click.once="savePicks">
                            Save Team
                        </button>
                    </div>
                    <div class="errors box" v-if="hasValidationErrors">
                        <h3>Errors</h3>
                        <ul>
                            <li v-for="error in validationErrors">
                                {{ error }}
                            </li>
                        </ul>
                    </div>
                    <div class="instructions hidden md:block">
                        <div>
                            <i class="fa-solid fa-arrow-right-arrow-left"></i> Switch Player
                        </div>
                        <div>
                            <i class="fa-solid fa-star captain-icon"></i> Captain
                        </div>
                        <div>
                            <i class="fa-solid fa-star vice-captain-icon"></i> Vice Captain
                        </div>
                    </div>
                </div>
                <fixtures :gameweek-id="gwId" :load-picks="true"></fixtures>
            </div>
        </div>
        <div id="player-list" class="box">
            <div class="player-list-table">
                <table>
                    <player-list-headers
                        @selectColumn="selectColumn($event)"
                        :columns="columns"
                    ></player-list-headers>
                    <tbody>
                    <player-list-row
                        v-for="player in players"
                        :key="player.id"
                        :player="player"
                        :stats="player"
                        :show-value="showValue"
                        :columns="columns"
                    ></player-list-row>
                    </tbody>
                </table>
            </div>
        </div>
        <subs-text class="m-3"></subs-text>
    </template>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {useTeamPickerStore} from "../stores/teamPickerStore.js";
import {computed, onMounted, ref, watch} from "vue";
import TeamPicker from "../components/TeamPicker.vue";
import SubsText from "../components/SubsText.vue";
import Fixtures from "../components/Fixtures.vue";
import dayjs from "dayjs";
import ChipInput from "@/components/ChipInput.vue";
import {useSeoMeta} from "@unhead/vue";
import {useFlashStore} from "@/stores/flashStore";
import PlayerListHeaders from "@/components/PlayerListHeaders.vue";
import PlayerListRow from "@/components/PlayerListRow.vue";
import playerColumns from "@/config/PlayerColumns";
import GameweekLockedBox from "@/components/GameweekLockedBox.vue";
import CountdownTimer from "@/components/CountdownTimer.vue";

export default {
    components: {
        CountdownTimer,
        GameweekLockedBox, PlayerListRow, PlayerListHeaders, ChipInput, Fixtures, SubsText, TeamPicker},
    setup() {
        const store = useSeasonStore()
        const teamPickerStore = useTeamPickerStore()

        useSeoMeta({
            title: () => 'Pick Team | ' + store.season.name,
            description: () => 'Pick Fantasy Team for ' + store.season.name
        })

        onMounted(() => {
            if (store.authEntry) {
                teamPickerStore.init(store.authEntry.id, store.transfersGameweek)
            }
            gameweekIsOpen.value = store.gameweekIsOpen()
        })

        const gameweekIsOpen = ref(null)

        const columns = playerColumns.filterByModel('player_season')

        const gwId = computed(() => {
            return teamPickerStore.gameweek.id
        })

        const activeGameweek = computed(() => {
            return teamPickerStore.gameweek
        })

        const starters = computed(() => {
            return _.pick(teamPickerStore.players, teamPickerStore.starters)
        })

        const loaded = computed(() => {
            return teamPickerStore.loaded
        })

        const authEntry = computed(() => {
            return store.authEntry
        })

        const hasChanged = computed(() => {
            return teamPickerStore.hasChanged
        })

        const hasValidationErrors = computed(() => {
            return teamPickerStore.hasValidationErrors
        })

        const validationErrors = computed(() => {
            return teamPickerStore.validationErrors
        })

        const players = computed(() => {
            return _.sortBy(teamPickerStore.players, player => {
                return teamPickerStore.sortOrder[player.id]
            })
        })

        const showValue = computed(() => {
            return store.season.budget !== null
        })

        const selectColumn = function(column) {
            console.log('Not implemented')
            /*
            if (filter.value.sort === column.field) {
                filter.value.sort_dir = filter.value.sort_dir === 'asc' ? 'desc' : 'asc'
            } else {
                filter.value.sort = column.field
                filter.value.sort_dir = 'desc'
            }
             */
        }

        const saving = ref(false)

        const savePicks = () => {
            saving.value = true
            teamPickerStore
                .savePicks()
                .then(response => {
                    useFlashStore().setFlash('success', 'Team Saved!')
                })
                .catch(error =>  {
                    useFlashStore().axiosError(error, 'Team failed to save')
                })
                .finally(() => saving.value = false)
        }

        const isValidSquadSize = computed(() => {
            return teamPickerStore.isValidSquadSize
        })

        return {loaded, gameweekIsOpen, columns, players, showValue, selectColumn, isValidSquadSize, authEntry, teamPickerStore, gwId, activeGameweek, starters,hasChanged, hasValidationErrors, validationErrors, saving, savePicks}
    }
}
</script>
