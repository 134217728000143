<template>
    <div>
        <div class="mx-3 mb-3 flex items-center">
            <button @click="saveAll" class="btn success">Save All</button>
            <p class="ml-3">Press enter on any input to save that row. Or click "Save All" to save all rows. Yellow background indicates data is not saved. All dates and times are in your local time.</p>
        </div>
        <table class="form-table">
            <thead>
            <tr>
                <th>ID</th>
                <th>Number</th>
                <th>Status</th>
                <th>Start</th>
                <th>End</th>
                <th>Locked</th>
                <th>Free Transfers</th>
                <th>Rollover Transfers</th>
                <th>Max Free Transfers</th>
                <th>Cost Per Transfer</th>
                <th>Picks Per Team</th>
                <th>Auto Subs</th>
            </tr>
            </thead>
            <tbody>
            <mod-gameweek-row
                v-for="gameweek in gameweeks"
                :key="gameweek.id"
                :gameweek="gameweek"
                v-model="draft[gameweek.id]"
                @saveRow="saveRow"
            ></mod-gameweek-row>
            </tbody>
        </table>
    </div>
</template>

<script>
import { useSeoMeta } from "@unhead/vue";
import { useSeasonStore } from "@/stores/seasonStore";
import { useFlashStore} from "@/stores/flashStore";
import { computed, ref } from "vue";
import ModGameweekRow from "@/components/ModGameweekRow.vue";

export default {
    components: {ModGameweekRow},
    props: {
        seasonId: {
            type: [Number, String],
            required: true
        }
    },
    setup() {
        const seasonStore = useSeasonStore();
        const flashStore = useFlashStore();

        useSeoMeta({
            title: () => 'Mod Gameweeks | ' + seasonStore.season.name,
            description: () => 'Mod Gameweeks ' + seasonStore.season.name
        });

        const gameweeks = computed(() => {
            return seasonStore.gameweeks;
        });

        const draft = ref(
            {...seasonStore.gameweeks}
        );

        const saveRow = (gameweekId) => {
            seasonStore.updateGameweek(gameweekId, draft.value[gameweekId])
                .then(() => {
                    flashStore.setFlash('success', 'Gameweek Updated')
                })
                .catch(error => flashStore.axiosError(error));
        };

        const saveAll = () => {
            seasonStore.updateGameweeks(draft.value)
                .then(() => {
                    flashStore.setFlash('success', 'All Gameweeks Updated')

                    draft.value = {...seasonStore.gameweeks};
                })
                .catch(error => flashStore.axiosError(error));
        };

        return { gameweeks, draft, saveAll, saveRow };
    }
};
</script>
