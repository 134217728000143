<template>
    <div v-if="entryNotFound">
        <p>Entry not found.</p>
    </div>
    <div v-else-if="!loaded">
        <div class="p-3">
            <p>Loading....</p>
        </div>
    </div>
    <div v-else-if="!activeGw">
        <div class="p-3">
            <p>No points have been recorded yet. Team points will appear here once the tournament starts.</p>
        </div>
    </div>
    <div v-else>
        <div class="flex flex-col lg:flex-row">
            <div v-if="groupedActivePlayerGameweeks.hasOwnProperty('starters') && groupedActivePlayerGameweeks.hasOwnProperty('subs')" class="order-2 lg:order-1 grow">
                <div class="flex flex-col lg:flex-row">
                    <div id="points-pitch-container" class="w-full lg:w-1/2">
                        <points-pitch
                            :player-gameweeks="activePlayerGameweeks"
                            :picks="picks[activeGwId]"
                        ></points-pitch>
                    </div>
                    <fixtures
                        class="w-full lg:w-1/2"
                        :key="activeGwId"
                        :gameweek-id="activeGwId"
                        :show-pagination="false"
                        :show-info="false"
                        :player-fixtures="playerFixtures[activeGwId]"
                        :picks="picks[activeGwId]"
                    ></fixtures>
                </div>
            </div>
            <div v-else class="box fixtures">
                <p>No points have been recorded for this gameweek yet.</p>
            </div>
            <div id="gw-overview" class="order-1 lg:order-2">
                <h1 style="font-size:28px;padding:0 1rem">{{ entry.name }}</h1>
                <div v-if="entryGameweeks" class="box purple-gradient" style="margin-top:0">
                    <div class="flex-default">
                        <h1>GW {{ activeGw.number }}</h1>
                        <gameweek-pagination
                            :active-gw="activeGwId"
                            :max-number="maxGwNumber"
                            @goTo="activeGwId = $event"
                        ></gameweek-pagination>
                    </div>
                    <div v-if="entryGameweeks.hasOwnProperty(activeGwId)" class="flex flex-col sm:flex-row lg:flex-col w-full justify-between	">
                        <div class="points-container">
                            <div class="points-value">{{ entryGameweeks[activeGwId].points || 0 }}</div>
                            <div class="points-label">Points</div>
                        </div>
                        <ul>
                            <li>
                                <strong>Avg Points:</strong> {{ activeGw.average_points }}
                            </li>
                            <li>
                                <strong>GW Rank:</strong> {{ entryGameweeks[activeGwId].rank }}
                            </li>
                            <li>
                                <strong>Overall Rank:</strong> {{ entryGameweeks[activeGwId].overall_rank }}
                                <span v-if="entryGameweeks[activeGwId].overall_rank_diff > 0" class="success-text rounded">
                                    +{{ entryGameweeks[activeGwId].overall_rank_diff }}
                                </span>
                                <span v-else-if="entryGameweeks[activeGwId].overall_rank_diff < 0" class="danger-text rounded">
                                    {{ entryGameweeks[activeGwId].overall_rank_diff }}
                                </span>
                            </li>
                            <li>
                                <strong>Points Scored:</strong> {{ entryGameweeks[activeGwId].points_scored }}
                            </li>
                            <li>
                                <strong>Points Spent:</strong> {{ entryGameweeks[activeGwId].points_spent }}
                            </li>
                            <li v-if="activeGw.number !== 1">
                                <strong>Transfers:</strong> {{ entryGameweeks[activeGwId].transfers || 0 }}
                            </li>
                            <li>
                                <strong>Chip:</strong> {{ activeSeasonChip ? activeSeasonChip.title : 'None' }}
                            </li>
                        </ul>
                    </div>
                    <template v-else>
                        No entry for GW {{ activeGw.number }}
                    </template>
                </div>
                <div v-if="!activeGw.calculated_auto_subs" class="box red-gradient">
                    <i class="fa-solid fa-circle-exclamation"></i> Auto subs still pending
                </div>
                <template v-else-if="autoSubs.hasOwnProperty(activeGwId) && autoSubs[activeGwId].length > 0">
                    <h1 style="font-size:28px;padding:0 1rem">Auto Subs</h1>
                    <p style="margin:0 1rem">{{ autoSubPoints[activeGwId] }} points from auto subs</p>
                    <div class="box blue-gradient" style="margin-top:0">
                        <ul class="transfers">
                            <template v-for="autoSub in autoSubs[activeGwId]">
                                <li class="transfer-points">
                                    {{ autoSub.points }} point(s)
                                </li>
                                <li class="transfer-in">
                                    <i class="fa-solid fa-arrow-right-arrow-left"></i>
                                    <a href="#" @click.prevent="modalPlayer = autoSub.in">
                                        {{ autoSub.in.name }}
                                        ({{ autoSub.in.position }})
                                    </a>
                                </li>
                                <li class="transfer-out">
                                    <i class="fa-solid fa-arrow-right-arrow-left"></i>
                                    <a href="#" @click.prevent="modalPlayer = autoSub.out">
                                        {{ autoSub.out.name }}
                                        ({{ autoSub.out.position }})
                                    </a>
                                </li>
                            </template>
                        </ul>
                    </div>
                </template>
                <template v-if="activeGw.number !== 1 && entryGameweeks.hasOwnProperty(activeGwId) && entryGameweeks[activeGwId].transfers > 0">
                    <h1 style="font-size:28px;padding:0 1rem">{{ entryGameweeks[activeGwId].transfers || 0 }} Transfers</h1>
                    <div class="box green-gradient" style="margin-top:0">
                        <ul v-if="transfers.hasOwnProperty(activeGwId)" class="transfers">
                            <template v-for="transfer in transfers[activeGwId]">
                                <li class="transfer-in">
                                    <i class="fa-solid fa-arrow-right-arrow-left"></i>
                                    <a href="#" @click.prevent="modalPlayer = transfer.player_in">{{ transfer.player_in.name }}</a>
                                </li>
                                <li v-if="transfer.player_out" class="transfer-out">
                                    <i class="fa-solid fa-arrow-right-arrow-left"></i>
                                    <a href="#" @click.prevent="modalPlayer = transfer.player_out">{{ transfer.player_out.name }}</a>
                                </li>
                            </template>
                        </ul>
                    </div>
                </template>
            </div>
        </div>

        <div class="stats-table">
            <player-gameweeks-table
                :player-gameweeks="playerGameweeks[activeGwId]"
                :picks="picks[activeGwId]"
            ></player-gameweeks-table>
        </div>

        <!--
        <div v-if="activeGw.doubles_count > 1">
            <player-gameweeks-table
                :player-gameweeks="playerGameweeks[activeGwId]"
                :picks="picks[activeGwId]"
            ></player-gameweeks-table>
        </div>
        <div class="stats-table">
            <player-fixtures-table
                :player-fixtures="playerFixtures[activeGwId]"
                :picks="picks[activeGwId]"
            ></player-fixtures-table>
        </div>
        -->

        <subs-text class="p-3"></subs-text>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, onMounted, ref} from "vue";
import GameweekPagination from "../components/GameweekPagination.vue";
import Fixtures from "../components/Fixtures.vue";
import PlayerResultTable from "../components/PlayerResultTable.vue";
import SubsText from "../components/SubsText.vue";
import dayjs from "dayjs";
import PointsPitch from "@/components/PointsPitch.vue";
import {useSeoMeta} from "@unhead/vue";
import PlayerGameweeksTable from "@/components/PlayerGameweeksTable.vue";
import PlayerFixturesTable from "@/components/PlayerFixturesTable.vue";

export default {
    components: {
        PlayerFixturesTable,
        PlayerGameweeksTable,
        PointsPitch,
        SubsText,
        PlayerResultTable,
        Fixtures,
        GameweekPagination
    },
    props: {
        entryId: {
            type: [Number,String],
            default: undefined
        }
    },
    setup(props) {
        const store = useSeasonStore()

        const season = computed(() => {
            return store.season
        })

        const loaded = ref(false)
        const entryNotFound = ref(false)
        const entry = ref(null)
        const entryGameweeks = ref(null)
        const playerFixtures = ref(null)
        const playerGameweeks = ref({})
        const picks = ref({})
        const transfers = ref(null)

        onMounted(() => {
            let entryId = props.entryId || _.get(store.authEntry, 'id');
            if (entryId) {
                axios
                    .get('/api/entry/' + entryId + '/points')
                    .then(response => {
                        entry.value = response.data.entry
                        entryGameweeks.value = _.keyBy(response.data.gameweeks, 'gameweek_id')
                        playerFixtures.value = _.groupBy(response.data.player_fixtures, playerFixture => {
                            return store.fixtures[playerFixture.fixture_id].gameweek_id
                        })
                        playerGameweeks.value = _.groupBy(response.data.player_gameweeks, 'gameweek_id')
                        picks.value = _.groupByKeyBy(response.data.picks, 'gameweek_id', 'player_season_id')
                        transfers.value = _.groupBy(response.data.transfers, 'gameweek_id')
                        loaded.value = true
                    })
            } else {
                entryNotFound.value = true
            }
        })

        useSeoMeta({
            title: () => entry.value ? 'Points | ' + entry.value.name + ' | ' + store.season.name : '',
            description: () => entry.value ? 'Fantasy Points for ' + entry.value.name + ' in Fantasy ' + store.season.name : ''
        })

        const maxGwNumber = computed(() => {
            return store.pointsGameweek.number
        })

        const activeGwId = ref(store.pointsGwId)

        const activeGw = computed(() => {
            return store.gameweeks[activeGwId.value]
        })

        const activeSeasonChipId = computed(() => {
            return entryGameweeks.value.hasOwnProperty(activeGwId.value)
                ? entryGameweeks.value[activeGwId.value].season_chip_id
                : null
        })

        const activeSeasonChip = computed(() => {
            return store.chips[activeSeasonChipId.value]
        })

        const starterIds = computed(() => {
            return _.map(_.filter(picks.value[activeGwId.value], pick => pick.is_starter === true), 'player_season_id')
        })

        const subIds = computed(() => {
            return _.map(_.filter(picks.value[activeGwId.value], pick => pick.is_sub === true), 'player_season_id')
        })

        const autoSubs = computed(() => {
            return _.mapValues(picks.value, gameweekPicks => {
                return _.map(_.filter(gameweekPicks, {status: 'subbed_off'}), entryPick => {
                    return {
                        in: store.players[entryPick.sub_id],
                        out: store.players[entryPick.player_season_id],
                        points: _.get(_.find(playerGameweeks.value[entryPick.gameweek_id], {player_season_id: entryPick.sub_id}), 'points') || 0
                    }
                })
            })
        })

        const autoSubPoints = computed(() => {
            return _.mapValues(autoSubs.value, autoSubs => {
                return _.sumBy(autoSubs, 'points')
            })
        })

        const autoSubDate = computed(() => {
            return dayjs(activeGw.value.auto_sub_at).format('D MMMM HH:mm')
        })

        const activePlayerGameweeks = computed(() => {
            return _.keyBy(_.sortBy(playerGameweeks.value[activeGwId.value], playerGameweek => {
                return picks.value[activeGwId.value][playerGameweek.player_season_id].position
            }), 'player_season_id')
        })

        const groupedActivePlayerGameweeks = computed(() => {
            return _.groupBy(
                _.sortBy(activePlayerGameweeks.value, playerGameweek => {
                    return picks.value[activeGwId.value][playerGameweek.player_season_id].position
                }),
                playerGameweek => {
                    return picks.value[activeGwId.value][playerGameweek.player_season_id].is_starter ? 'starters' : 'subs'
                }
            )

            /*
            return _.chain(picks.value[activeGwId.value])
                .sortBy('position')
                .map(pick => {
                    return {
                        pick: pick,
                        groupName: pick.is_starter ? 'starters' : 'subs',
                        gameweeks: _.filter(playerGameweeks.value[activeGwId.value], {
                            gameweek_id: activeGwId.value,
                            player_season_id: pick.player_season_id
                        }),
                        fixtures: _.filter(playerFixtures.value[activeGwId.value],{
                            player_season_id: pick.player_season_id
                        })
                    }
                })
                .groupBy('groupName')
                .value()
            */
        })

        return {loaded, entryNotFound, season, maxGwNumber, activeGw, activeGwId, activeSeasonChip, entry, entryGameweeks, playerGameweeks, activePlayerGameweeks, groupedActivePlayerGameweeks, playerFixtures, picks, transfers, autoSubs, autoSubPoints, autoSubDate, starterIds, subIds}
    }
}
</script>
