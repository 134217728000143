<template>
    <div>
        <div id="create-player-form" class="box form">
            <h1>Create Player</h1>
            <form @submit.prevent="submitForm">
                <div v-if="requirePlatformUrl" class="mb-3">
                    <label for="platform_url">Platform Url</label>
                    <input type="text" id="platform_url"
                           required
                           placeholder="https://www.sofascore.com/player/john-doe/1234567"
                           v-model="platformUrl">
                </div>
                <div class="mb-3">
                    <label for="name">Name</label>
                    <input type="text" id="name"
                           required
                           v-model="name">
                </div>
                <div class="mb-3">
                    <label for="display_name">Display Name</label>
                    <input type="text" id="display_name"
                           required
                           v-model="displayName">
                </div>
                <div class="mb-3">
                    <label for="position">Position</label>
                    <select
                        id="position"
                        v-model="position"
                        required
                    >
                        <option :value="null">Pick Position</option>
                        <option v-for="position in positions" :value="position">{{ position }}</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label for="value">Value</label>
                    <input type="number" id="value"
                           :required="requireValue"
                           max="200"
                           v-model="value">
                </div>
                <div class="mb-3">
                    <label for="team">Team</label>
                    <select
                        id="team"
                        v-model="teamId"
                        required
                    >
                        <option :value="null">Pick Team</option>
                        <option v-for="team in teams" :value="team.id">{{ team.name }}</option>
                    </select>
                </div>
                <div class="mb-3">
                    <button type="submit" :disabled="!isValid" class="success">
                        Create Player
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import {computed, onMounted, ref} from "vue";
import FormButton from "../elements/FormButton.vue";
import {useSeasonStore} from "../stores/seasonStore";
import playersQuery from "../queries/playersQuery";
import router from "../routes";
import {useSeoMeta} from "@unhead/vue";
import {useFlashStore} from "@/stores/flashStore";

export default {
    components: {FormButton},
    setup(props, {emit}) {
        useSeoMeta({
            title: 'Create Player',
            description: 'Create a new player for the season',
        })

        const seasonStore = useSeasonStore()

        const season = computed(() => seasonStore.season)
        const teamId = ref(null)
        const platformUrl = ref('')
        const name = ref('')
        const displayName = ref('')
        const position = ref(null)
        const value = ref('')
        const requireValue = computed(() => seasonStore.season.budget !== null)
        const requirePlatformUrl = computed(() => seasonStore.season.id !== 20048)

        const isValid = computed(() => {
            return teamId.value
                && (platformUrl.value || !requirePlatformUrl.value)
                && name.value
                && displayName.value
                && position.value
                && (!requireValue.value || value.value)
        })

        const teams = computed(() => {
            return _.sortBy(seasonStore.teams, 'name')
        })

        const positions = ['GK', 'DEF', 'MID', 'ATT']

        const submitForm = () => {
            if (isValid.value) {
                playersQuery.create(
                    seasonStore.seasonId,
                    teamId.value,
                    platformUrl.value,
                    name.value,
                    displayName.value,
                    position.value,
                    value.value
                )
                    .then(response => {
                        console.log(response)
                        seasonStore.players[response.data.player_season.id] = response.data.player_season
                        router.push({ name: 'admin-values' })
                    })
                    .catch(error =>  {
                        useFlashStore().axiosError(error, 'Error Creating Player')
                    })
            } else {
                useFlashStore().setFlash('error', 'Form is not valid')
            }
        }

        return {
            season,
            teams,
            positions,
            isValid,
            teamId,
            platformUrl,
            name,
            displayName,
            position,
            value,
            requireValue,
            requirePlatformUrl,
            submitForm
        }
    }
}
</script>
