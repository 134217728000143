<template>
    <tr>
        <th>
            <span class="mx-3">{{ team.id }}</span>
        </th>
        <td>
            <form @submit.prevent="saveData">
                <input
                    type="text"
                    v-model="localDraft.name"
                    @input="emitChanges"
                    placeholder="Name"
                    :class="{ warning: localDraft.name !== team.name }"
                />
            </form>
        </td>
        <td>
            <form @submit.prevent="saveData">
                <input
                    type="text"
                    v-model="localDraft.short_name"
                    @input="emitChanges"
                    placeholder="Short Name"
                    :class="{ warning: localDraft.short_name !== team.short_name }"
                />
            </form>
        </td>
    </tr>
</template>

<script>
import { ref, watch } from "vue";
import { useSeasonStore } from "../stores/seasonStore";
import { useFlashStore } from "@/stores/flashStore";

export default {
    emits: ['update:modelValue', 'saveRow'],
    props: {
        team: {
            type: Object,
            required: true
        },
        modelValue: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props, { emit }) {
        const localDraft = ref({ ...props.modelValue });

        watch(
            () => props.modelValue,
            (newVal) => {
                localDraft.value = { ...newVal }; // Update localDraft if modelValue changes
            }
        );

        const emitChanges = () => {
            emit('update:modelValue', localDraft.value);
        };

        const saveData = () => {
            emit('saveRow', props.team.id); // Emit event to save the row in parent
        };

        return { localDraft, emitChanges, saveData };
    }
};
</script>
