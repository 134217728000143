<template>
    <transfers-list ref="transfersListRef" :route-name="routeName" :player-id="player.player_id">
        <template v-slot:header>
            <div v-if="header" class="header">
                <h1>{{ header }}</h1>
            </div>
        </template>
    </transfers-list>
</template>

<script>
import {watch, ref} from "vue";
import TransfersList from "@/components/TransfersList.vue";

export default {
    components: {TransfersList},
    props: {
        player: {
            type: Object,
            required: true
        },
        routeName: {
            type: String,
            required: true
        },
        header: {
            type: String,
            required: false
        }
    },
    setup(props) {
        // Create a reference to the TransfersList component
        const transfersListRef = ref(null);

        watch(() => props.player, (newPlayer, oldPlayer) => {
            // The method getPage() on transfers-list
            transfersListRef.value.getPage()
        })

        return {transfersListRef}
    }
}
</script>
