<template>
    <div id="fixture-page">
        <fixture-header :fixture="fixture"></fixture-header>
        <div class="fixture-header">
            <div class="grid grid-cols-1 sm:grid-cols-2">
                <div class="justify-self-end">
                    <div class="row">
                        <template
                            v-for="playerFixture in homePlayerFixtures"
                            :key="playerFixture.id">
                            <div v-if="playerFixture.minutes > 0" class="text-right mr-3 mb-3">
                                <div><small>({{ players[playerFixture.player_season_id].position }})</small> <strong>{{ players[playerFixture.player_season_id].name }}</strong> ({{ playerFixture.points }})</div>
                                <template v-for="(value, key) in playerFixture.points_calculation">
                                    <small v-if="value !== 0" class="ml-3">
                                        {{ key }}: {{ value }}
                                    </small>
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
                <div>
                    <div class="row">
                        <template
                            v-for="playerFixture in awayPlayerFixtures"
                            :key="playerFixture.id">
                            <div v-if="playerFixture.minutes > 0" class="text-left mr-3 mb-3">
                                <div><small>({{ players[playerFixture.player_season_id].position }})</small> <strong>{{ players[playerFixture.player_season_id].name }}</strong> ({{ playerFixture.points }})</div>
                                <template v-for="(value, key) in playerFixture.points_calculation">
                                    <small v-if="value !== 0" class="mr-3">
                                        {{ key }}: {{ value }}
                                    </small>
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div id="player-list" class="box">
            <player-fixtures-table
                :fixture="fixture"
                :player-fixtures="playerFixtures"
            ></player-fixtures-table>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, onMounted, ref} from "vue";
import dayjs from "dayjs";
import FixtureHeader from "../components/FixtureHeader.vue";
import {useSeoMeta} from "@unhead/vue";
import PlayerListHeaders from "@/components/PlayerListHeaders.vue";
import PlayerListRow from "@/components/PlayerListRow.vue";
import playerColumns from "@/config/PlayerColumns";
import PlayerFixturesTable from "@/components/PlayerFixturesTable.vue";

export default {
    components: {PlayerFixturesTable, PlayerListRow, PlayerListHeaders, FixtureHeader},
    props: {
        fixtureId: {
            type: [String, Number],
            required: true
        }
    },
    setup(props) {
        const store = useSeasonStore()
        const fixture = computed(() => {
            return store.fixtures[props.fixtureId]
        })

        useSeoMeta({
            title: () => fixture.value.description + ' | Fantasy ' + store.season.name,
            description: () => fixture.value.description
        })

        onMounted(() => {
            axios
                .get('/api/fixture/' + props.fixtureId + '/stats')
                .then(response => {
                    players.value = _.pick(store.players, _.map(response.data.playerFixtures, 'player_season_id'))

                    playerFixtures.value = _.orderBy(
                        response.data.playerFixtures,
                        [
                            (playerFixture) => playerFixture.team_id === fixture.value.home_team_id ? 0 : 1,
                            (playerFixture) => playerFixture.started ? 0 : 1,
                            (playerFixture) => players.value[playerFixture.player_season_id].position_sort,
                            (playerFixture) => players.value[playerFixture.player_season_id].name
                        ],
                        ['asc', 'asc', 'asc', 'asc']
                    )

                    homePlayerFixtures.value = _.filter(playerFixtures.value, {team_id: fixture.value.home_team_id})
                    awayPlayerFixtures.value = _.filter(playerFixtures.value, {team_id: fixture.value.away_team_id})
                })
        })

        const players = ref({})
        const playerFixtures = ref([])
        const homePlayerFixtures = ref([])
        const awayPlayerFixtures = ref([])

        const homeTeam = computed(() => {
            return store.teams[fixture.value.home_team_id]
        })

        const awayTeam = computed(() => {
            return store.teams[fixture.value.away_team_id]
        })

        const prettyDate = dayjs(fixture.value.kickoff).format('dddd MMMM D, YYYY');
        const prettyTime = dayjs(fixture.value.kickoff).format('HH:mm')

        return { fixture, players, playerFixtures, homePlayerFixtures, awayPlayerFixtures, homeTeam, awayTeam, prettyDate, prettyTime}
    }
}
</script>

