<template>
    <div id="season-form" class="form box">
        <h1>{{ season ? 'Edit Season' : 'Create Season' }}</h1>
        <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="platform">Platform</label>
                <input id="platform"
                       type="text"
                       placeholder="Platform"
                       required
                       v-model="attributes.platform">
            </div>
            <div class="mb-3">
                <label for="platform_id">Platform ID</label>
                <input id="platform_id"
                       type="text"
                       placeholder="Platform ID"
                       required
                       v-model="attributes.platform_id">
            </div>
            <div class="mb-3">
                <label for="type">Type</label>
                <select
                    id="type"
                    v-model="attributes.type"
                    required
                >
                    <option v-for="(typeLabel, typeKey) in typeOptions" :value="typeKey">
                        {{ typeLabel }}
                    </option>
                </select>
            </div>
            <div class="mb-3">
                <label for="name">Name</label>
                <input id="name"
                       type="text"
                       placeholder="Name"
                       required
                       v-model="attributes.name">
            </div>
            <div class="mb-3">
                <label for="short_name">Short Name</label>
                <input id="short_name"
                       type="text"
                       placeholder="Short Name"
                       required
                       v-model="attributes.short_name">
            </div>
            <div class="mb-3">
                <label for="status">Status</label>
                <select
                    id="status"
                    v-model="attributes.status"
                    required
                >
                    <option v-for="(statusLabel, statusKey) in statusOptions" :value="statusKey">
                        {{ statusLabel }}
                    </option>
                </select>
            </div>
            <div class="mb-3">
                <label for="start_at">Start At</label>
                <input id="start_at"
                       type="datetime-local"
                       placeholder="Start At"
                       required
                       v-model="attributes.start_at">
            </div>
            <div class="mb-3">
                <label for="end_at">End At</label>
                <input id="end_at"
                       type="datetime-local"
                       placeholder="End At"
                       required
                       v-model="attributes.end_at">
            </div>
            <div class="mb-3">
                <label for="gender">Gender</label>
                <select
                    id="gender"
                    v-model="attributes.gender"
                    required
                >
                    <option v-for="(genderLabel, genderKey) in genderOptions" :value="genderKey">
                        {{ genderLabel }}
                    </option>
                </select>
            </div>
            <div class="mb-3">
                <label for="budget">Budget</label>
                <input id="budget"
                       type="number"
                       placeholder="Budget"
                       v-model="attributes.budget">
            </div>
            <div class="mb-3">
                <label for="free_transfers">Free Transfers</label>
                <input id="free_transfers"
                       type="number"
                       placeholder="Free Transfers"
                       required
                       v-model="attributes.free_transfers">
            </div>
            <div class="mb-3">
                <label for="cost_per_transfer">Cost Per Transfer</label>
                <input id="cost_per_transfer"
                       type="number"
                       placeholder="Cost Per Transfer"
                       required
                       v-model="attributes.cost_per_transfer">
            </div>
            <div class="mb-3">
                <label for="nation_code">Nation Code</label>
                <input id="nation_code"
                       type="text"
                       placeholder="Nation Code"
                       required
                       v-model="attributes.nation_code">
            </div>
            <div class="mb-3">
                <button :disabled="sending" class="success">
                    {{ sending ? 'Saving...' : 'Save' }}
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import {onMounted, ref} from "vue";
import {useGodStore} from "@/stores/godStore";
import {useFlashStore} from "@/stores/flashStore";

export default {
    emits: ['success', 'error'],
    props: {
        season: {
            type: Object,
            required: false
        }
    },
    setup(props, {emit}) {
        const godStore = useGodStore()

        onMounted(() => {
            if (props.season) {
                const league = _.first(props.season.leagues)

                attributes.value = {
                    ...attributes.value,
                    ..._.pick(league, _.keys(attributes.value)),
                    ..._.pick(props.season, _.keys(attributes.value)),
                    start_at: new Date(props.season.start_at).toISOString().slice(0, 16),
                    end_at: new Date(props.season.end_at).toISOString().slice(0, 16),
                }
            }
        })

        const typeOptions = {
            league: 'League',
            groups_knockout: 'Groups Knockout',
            league_playoff: 'League Playoff'
        }

        const statusOptions = {
            active: 'Active',
            hidden: 'Hidden',
            finished: 'Finished',
            historical: 'Historical'
        }

        const genderOptions = {
            m: 'Men',
            f: 'Women'
        }

        const attributes = ref({
            platform: null,
            platform_id: null,
            type: null,
            name: null,
            short_name: null,
            status: null,
            start_at: (new Date).toISOString().slice(0, 16),
            end_at: (new Date).toISOString().slice(0, 16),
            gender: 'm',
            budget: 1000,
            free_transfers: 2,
            cost_per_transfer: 4,
            nation_code: null,
        })

        const sending = ref(false)

        const submitForm = () => {
            sending.value = true

            const request = props.season
                ? godStore.updateSeason(props.season, attributes.value)
                : godStore.createSeason(attributes.value)

            request
                .then(response => {
                    useFlashStore().setFlash('success', 'Saved!')

                    emit('success', response)
                })
                .catch(error => {
                    useFlashStore().axiosError(error, 'Error Saving Season')

                    emit('error', error)
                })
                .finally(() => {
                    sending.value = false
                })
        }

        return {
            typeOptions,
            statusOptions,
            genderOptions,
            attributes,
            sending,
            submitForm
        }
    }
}
</script>
