<template>
    <div v-if="rows" class="box">
        <h1>Historical Stats</h1>
        <div class="stats-table">
            <div class="player-list-table">
                <table>
                    <player-list-headers
                        :columns="columns"
                    ></player-list-headers>
                    <tbody>
                    <player-list-row
                        v-for="row in rows"
                        :key="row.id"
                        :player="player"
                        :stats="row"
                        :columns="columns"
                    ></player-list-row>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import PlayerColumns from "@/config/PlayerColumns";
import PlayerListHeaders from "@/components/PlayerListHeaders.vue";
import PlayerListRow from "@/components/PlayerListRow.vue";

export default {
    components: {PlayerListRow, PlayerListHeaders},
    props: {
        player: {
            type: Object,
            required: true
        },
        rows: {
            type: Array,
            required: true
        }
    },
    emits: ['close'],
    setup(props) {
        const columns = PlayerColumns.filterByModel('historical_stats')

        return {columns}
    }
}
</script>
