<template>
    <div v-if="loaded" id="pick-squad-container" class="flex flex-col lg:flex-row lg:items-start">
        <div id="pitch-anchor" class="pick-squad-pitch order-2 md:order-1">
            <squad-picker></squad-picker>
        </div>
        <div class="pick-squad-form order-1 md:order-2">
            <div id="transfers-form" class="box">
                <router-link :to="{name: 'season-settings'}" class="flex items-center">
                    <h3>{{ authEntry.name }}</h3>
                    <i class="ml-3 fa-solid fa-pen-to-square"></i>
                </router-link>
                <div class="paragraphs">
                    <p>Click <i class="fa-solid fa-arrow-right-arrow-left"></i> on the pitch view to swap a player from the market. Then hit the save button when you're done.</p>
                    <p>Your transfers will take affect for <strong>GW {{ transfersGameweek.number }}</strong>.</p>
                    <p>The deadline for transfers is <strong>{{ deadlineText }}</strong></p>
                    <p v-if="showValue">Budget Remaining: <strong>$ {{ moneyRemaining }}</strong></p>
                    <p>Max Players Per Team: <strong>{{ transfersGameweek.picks_per_team || "Unlimited" }}</strong></p>
                </div>
                <!--
                <chip-input
                    class="mt-3"
                    v-model:seasonChipId="squadPickerStore.seasonChipId"
                    v-model:chipOptionId="squadPickerStore.chipOptionId"
                    :gameweek="transfersGameweek"
                ></chip-input>
                -->
                <div style="padding:1rem 0;">
                    <button v-if="hasValidationErrors" :disabled="true">
                        Team Invalid
                    </button>
                    <button v-else-if="!hasChanged" :disabled="true">
                        Team Already Saved
                    </button>
                    <button v-else-if="saving" :disabled="true">
                        Saving...
                    </button>
                    <button v-else @click.once="savePicks">
                        Save Team
                    </button>
                </div>
                <div class="errors box" v-if="inactivePlayers.length > 0">
                    <h3>Inactive Players</h3>
                    <p>The following players are no longer participating.</p>
                    <ul>
                        <li v-for="inactivePlayer in inactivePlayers">
                            {{ inactivePlayer.name }}
                        </li>
                    </ul>
                </div>
                <div class="errors box" v-if="hasValidationErrors">
                    <h3>Errors</h3>
                    <ul>
                        <li v-for="error in validationErrors">
                            {{ error }}
                        </li>
                    </ul>
                </div>
                <div v-if="transfers.length > 0" class="transfers">
                    <strong>{{ transfers.length }} Transfers:</strong>
                    <ul>
                        <template v-for="transfer in transfers">
                            <li v-if="transfer.in" class="transfer" :class="{'hide-value':!showValue}">
                                <a href="#">{{ transfer.in.name }} <span class="player-value">({{ transfer.in_value }})</span></a>
                                <i class="fa-solid fa-arrow-right-arrow-left" style="margin:0 .5rem"></i>
                                <a href="#">{{ transfer.out.name }} <span class="player-value">({{ transfer.out_value }})</span></a>
                                <span v-if="transfer.diff > 0" class="diff positive-number">
                                    +{{ transfer.diff }}
                                </span>
                                <span v-else-if="transfer.diff < 0" class="diff negative-number">
                                    {{ transfer.diff }}
                                </span>
                                <span v-else-if="transfer.diff" class="diff equal-number">
                                    &nbsp;{{ transfer.diff }}
                                </span>
                                <button @click.prevent.stop="swapPlayers(transfer.out.id, transfer.in.id)" class="undo-button">
                                    Undo
                                </button>
                            </li>
                        </template>
                    </ul>
                </div>
                <div v-if="unlimitedFreeTransfers" class="mt-3">
                    All transfers are free this GW.
                </div>
                <div v-else class="mt-3">
                    <span>
                        You have <span class="warning-text">{{ freeTransfersRemaining }}</span> free transfers left this gameweek.
                    </span>
                    <template v-if="freeTransfersRemaining">
                        <span v-if="transfersGameweek.rollover_free_transfers > 0">
                            <span class="warning-text">{{ transfersGameweek.rollover_free_transfers }}</span> transfer(s) will roll over to next week, upto a maximum of <span class="warning-text">{{ transfersGameweek.max_free_transfers }}</span> free transfers.
                        </span>
                        <span v-else>
                            Transfers do not roll over week to week.
                        </span>
                    </template>
                    <span v-if="transfers.length > 0">
                        Each additional transfer costs <span class="warning-text">{{ transfersGameweek.cost_per_transfer }}</span> points.
                        These transfers will cost you <span :class="pointsCost > 0? 'danger-text' : 'success-text'">{{ pointsCost }} points</span>.
                    </span>
                    <span v-if="existingActiveTransfersCount > 0">You have already made <span class="warning-text">{{ existingActiveTransfersCount }}</span> transfers this GW.</span>
                </div>
            </div>
        </div>
        <div class="pick-squad-player-list overflow-scroll order-3">
            <player-list
                :selected-player-id="selectedPlayer ? selectedPlayer.id : null"
                :picks="picks"
                @selectPlayer="selectPlayer"
                :affordableValue="affordableValue"
                class="box"
            ></player-list>
        </div>
        <div class="pick-squad-fixtures order-4">
            <fixtures :show-pagination="false" :gameweek-id="transfersGameweek.id"></fixtures>
        </div>
        <div v-if="showValue && !isFirstTime" class="pick-squad-values order-5">
            <squad-values-table></squad-values-table>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {useSquadPickerStore} from "../stores/squadPickerStore.js";
import {useFlashStore} from "../stores/flashStore";
import {computed, onMounted, ref} from "vue";
import PlayerList from "../components/PlayerList.vue";
import SquadPicker from "../components/SquadPicker.vue";
import Fixtures from "../components/Fixtures.vue";
import dayjs from 'dayjs';
import SquadValuesTable from "./SquadValuesTable.vue";
import ExplanationPrice from "./ExplanationPrice.vue";
import ChipInput from "@/components/ChipInput.vue";

export default {
    components: {
        ChipInput,
        ExplanationPrice, SquadValuesTable, Fixtures, SquadPicker, PlayerList},
    setup() {
        const store = useSeasonStore()
        const squadPickerStore = useSquadPickerStore()

        onMounted(() => {
            squadPickerStore.init(authEntry.value.id, transfersGameweek.value)
        })

        const isFirstTime = computed(() => {
            return !Array.isArray(squadPickerStore.originals) || squadPickerStore.originals.length === 0
        })

        const showValue = computed(() => {
            return store.season.budget !== null
        })

        const authEntry = computed(() => {
            return store.authEntry
        })

        const selectedPlayer = computed(() => {
            return squadPickerStore.selectedPlayer
        })

        const hasChanged = computed(() => {
            return squadPickerStore.hasChanged
        })

        const loaded = computed(() => {
            return squadPickerStore.loaded
        })

        const transfers = computed(() => {
            return _.map(Object.keys(squadPickerStore.transfers), outId => {
                return {
                    in: store.players[squadPickerStore.transfers[outId]],
                    in_value: squadPickerStore.inValues[squadPickerStore.transfers[outId]] / 10,
                    out: store.players[outId],
                    out_value: squadPickerStore.outValues[outId] / 10,
                    diff: (parseInt(squadPickerStore.outValues[outId]) - parseInt(squadPickerStore.inValues[squadPickerStore.transfers[outId]])) / 10
                }
            })
        })

        const hasValidationErrors = computed(() => {
            return squadPickerStore.hasValidationErrors
        })

        const validationErrors = computed(() => {
            return squadPickerStore.validationErrors
        })

        const transfersGameweek = computed(() => {
            return store.transfersGameweek
        })

        const freeTransfersRemaining = computed(() => {
            return squadPickerStore.freeTransfersRemaining
        })

        const allowedFreeTransfers = computed(() => {
            return squadPickerStore.allowedFreeTransfers
        })

        const unlimitedFreeTransfers = computed(() => {
            return squadPickerStore.unlimitedFreeTransfers
        })

        const moneyRemaining = computed(() => {
            return squadPickerStore.moneyRemaining
        })

        const affordableValue = computed(() => {
            return squadPickerStore.affordableValue
        })

        const pointsCost = computed(() => {
            return squadPickerStore.pointsCost
        })

        const deadlineText = computed(() => {
            return dayjs(transfersGameweek.value.locked_at).format('dddd MMMM D h:mm A')
        })

        const saving = ref(false)

        const savePicks = () => {
            saving.value = true
            squadPickerStore
                .savePicks()
                .then(() => {
                    useFlashStore().setFlash('success', 'Your team has been saved')
                })
                .catch(error =>  {
                    console.error(error)
                    useFlashStore().axiosError(error, 'Team failed to save')
                })
                .finally(() => saving.value = false)
        }

        const swapPlayers = (inId, outId) => {
            squadPickerStore.swapPlayers(inId, outId)
        }

        const existingActiveTransfersCount = computed(() => {
            return squadPickerStore.existingActiveTransfersCount
        })

        const picks = computed(() => {
            return squadPickerStore.picks
        })

        const selectPlayer = (playerId) => {
            squadPickerStore.selectPlayer(playerId)

            // Only scroll up when team is valid
            if (picks.length === 15) {
                document.getElementById('pitch-anchor').scrollIntoView({
                    behavior: 'smooth'
                })
            }
        }

        const inactivePlayers = computed(() => {
            return _.filter(squadPickerStore.players, {status: 'inactive'})
        })

        return {squadPickerStore, isFirstTime, showValue, selectPlayer, existingActiveTransfersCount, picks, loaded, authEntry, selectedPlayer, hasChanged, transfers, hasValidationErrors, validationErrors, inactivePlayers, transfersGameweek, moneyRemaining, affordableValue, freeTransfersRemaining, allowedFreeTransfers, unlimitedFreeTransfers, pointsCost, deadlineText, saving, savePicks, swapPlayers}
    }
}
</script>
