<template>
    <div id="player-profile" class="player-profile">
        <div class="header">
            <img :src="player.flag_path" class="flag"/>
            <h1>{{ player ? player.name : 'Error' }}</h1>
        </div>
        <div class="details">
            <ul>
                <li>
                    {{ player.position }}
                </li>
                <li>
                    {{ team.name }}
                </li>
                <li>
                    {{ player.points }} Points
                </li>
                <li>
                    Owned By {{ (player.selected_by_percent || 0).toFixed(2) }} %
                </li>
            </ul>
        </div>

        <div class="stats-table">
            <div class="player-list-table">
                <table>
                    <player-list-headers
                        :columns="columns"
                    ></player-list-headers>
                    <tbody>
                    <player-list-row
                        :key="player.id"
                        :player="player"
                        :stats="player"
                        :columns="columns"></player-list-row>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="mt-5">
            <h1>Fixtures</h1>
        </div>
        <div v-if="season.has_started" class="pt-3 grid grid-cols-1 xl:grid-cols-12 gap-3">
            <div class="md:col-span-4 flex">
                <div id="fixtures" class="fixtures box">
                    <table>
                        <tbody>
                        <fixture-table-row
                            v-for="fixture in fixtures"
                            :key="fixture.id"
                            :fixture="fixture"
                            :participant-id="team.id"
                        ></fixture-table-row>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="md:col-span-8">
                <div class="results w-full ">
                    <player-result-table
                        :player-gameweeks="playerGameweeks"
                        :player-fixtures="playerFixtures"
                    ></player-result-table>
                </div>
            </div>
        </div>
        <player-historical-stats
            :player="player"
        ></player-historical-stats>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, onMounted, ref} from "vue";
import FixtureTableRow from "../components/FixtureTableRow.vue";
import PlayerResultTable from "./PlayerResultTable.vue";
import PlayerSeasonsStats from "./PlayerSeasonsStats.vue";
import PlayerHistoricalStats from "@/components/PlayerHistoricalStats.vue";
import playerColumns from "@/config/PlayerColumns";
import PlayerListHeaders from "@/components/PlayerListHeaders.vue";
import PlayerListRow from "@/components/PlayerListRow.vue";

export default {
    components: {
        PlayerListRow, PlayerListHeaders,
        PlayerHistoricalStats,
        PlayerSeasonsStats,
        PlayerResultTable,
        FixtureTableRow
    },
    props: {
        player: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const store = useSeasonStore()

        const team = computed(() => {
            return store.teams[props.player.team_id]
        })

        const fixtures = computed(() => {
            return store.getTeamFixtures(team.value.id)
        })

        const season = computed(() => {
            return store.season
        })

        const playerGameweeks = ref([])
        const playerFixtures = ref([])

        onMounted(async () => {
            await axios
                .get('/api/player-fixtures/' + props.player.id)
                .then(response => {
                    playerGameweeks.value = _.orderBy(response.data.player_gameweeks, 'gameweek_id', 'desc')
                    playerFixtures.value = response.data.player_fixtures
                })
        })

        const columns = playerColumns.filterByModel('player_season', ['info_icon','name'])

        return {team, fixtures, season, playerGameweeks, playerFixtures, store, columns}
    }
}
</script>
