<template>
    <div class="countdown-timer" v-if="timeLeft >= 0">
        <span>{{ hours }}:</span>
        <span>{{ minutes }}:</span>
        <span>{{ seconds }}</span>
    </div>
    <div v-else>
        <slot name="onComplete">Time's up!</slot>
    </div>
</template>

<script>
import {computed, onMounted, onUnmounted, ref} from "vue";

export default {
    props: {
        timestamp: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const timeLeft = ref(0);

        const hours = computed(() => Math.floor(timeLeft.value / 3600).toString().padStart(2, '0'));
        const minutes = computed(() => Math.floor((timeLeft.value % 3600) / 60).toString().padStart(2, '0'));
        const seconds = computed(() => Math.floor(timeLeft.value % 60).toString().padStart(2, '0'));

        function updateCountdown() {
            const now = Math.floor(Date.now() / 1000); // Get the current time in seconds
            timeLeft.value = props.timestamp - now;
        }

        let interval;

        onMounted(() => {
            updateCountdown(); // Initial update
            interval = setInterval(updateCountdown, 1000); // Update every minute
        });

        onUnmounted(() => {
            clearInterval(interval);
        });

        return {
            timeLeft, hours, minutes, seconds
        }
    }
}
</script>
