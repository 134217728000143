<template>
    <div>
        <season-mod-nav-bar></season-mod-nav-bar>
        <router-view v-if="canMod"></router-view>
        <div v-else>
            You do not have permission to view this page.
        </div>
    </div>
</template>
<script>
import {computed} from "vue";
import {useSeasonStore} from "@/stores/seasonStore";
import {useSeoMeta} from "@unhead/vue";
import SeasonModNavBar from "@/components/SeasonModNavBar.vue";

export default {
    components: {SeasonModNavBar},
    setup() {
        const seasonStore = useSeasonStore()

        useSeoMeta({
            title: () => 'Mod | ' + seasonStore.season.name,
            description: () => 'Mod area'
        })

        const canMod = computed(() => {
            return seasonStore.authCanMod
        })

        return {canMod}
    }
}
</script>
