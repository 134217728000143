<template>
    <div class="player-list-table">
        <table>
            <player-list-headers
                @selectColumn="selectColumn($event)"
                :columns="columns"
                :filter="filter"
            ></player-list-headers>
            <tbody>
            <player-list-row
                v-for="playerFixture in sortedPlayerFixtures"
                :key="playerFixture.player_season_id"
                :player="players[playerFixture.player_season_id]"
                :stats="playerFixture"
                :pick="picks[playerFixture.player_season_id]"
                :columns="columns"
                route-name="player">
            </player-list-row>
            </tbody>
        </table>
    </div>
</template>
<script>
import PlayerListHeaders from "@/components/PlayerListHeaders.vue";
import PlayerListRow from "@/components/PlayerListRow.vue";
import playerColumns from "@/config/PlayerColumns";
import {computed, ref} from "vue";
import {useSeasonStore} from "@/stores/seasonStore";

export default {
    props: {
        playerFixtures: {
            type: Array,
            required: true
        },
        picks: {
            type: Object,
            default: () => ({})
        },
        fixture: {
            type: Object,
            default: null
        }
    },
    components: {PlayerListHeaders, PlayerListRow},
    setup(props) {
        const seasonStore = useSeasonStore()
        const columns = props.fixture
            ? playerColumns.filterByModel('player_fixture', ['gameweek', 'date', 'opposition', 'score'])
            : playerColumns.filterByModel('player_fixture', )

        const filter = ref({
            sort: null,
            sort_dir: null
        });

        const selectColumn = function(column) {
            const sortField = column.sort_field || column.field
            const sortDir = column.sort_dir || 'desc'
            if (filter.value.sort === sortField && filter.value.sort_dir !== sortDir) {
                filter.value = {
                    sort: null,
                    sort_dir: null
                }
            } else if(filter.value.sort === sortField) {
                filter.value = {
                    sort: sortField,
                    sort_dir: sortDir === 'asc' ? 'desc' : 'asc'
                }
            } else {
                filter.value = {
                    sort: sortField,
                    sort_dir: sortDir
                }
            }
        }

        const players = computed(() => {
            return _.pick(seasonStore.players, _.map(props.playerFixtures, 'player_season_id'))
        })

        const sortedPlayerFixtures = computed(() => {
            if (filter.value.sort === null || (filter.value.sort === 'team' && filter.value.sort_dir === 'asc')) {
                return props.playerFixtures
            } else if(filter.value.sort === 'team') {
                return props.playerFixtures.reverse()
            } else {
                return _.orderBy(
                    props.playerFixtures,
                    playerFixture => {
                        if (filter.value.sort === 'position') {
                            return players.value[playerFixture.player_season_id].position_sort
                        } else if(playerFixture.hasOwnProperty(filter.value.sort)) {
                            return playerFixture[filter.value.sort]
                        } else {
                            return players.value[playerFixture.player_season_id][filter.value.sort]
                        }
                    },
                    filter.value.sort_dir
                )
            }
        })

        return {
            columns,
            selectColumn,
            filter,
            players,
            sortedPlayerFixtures
        }
    }
}
</script>
