<template>
    <div id="gameweek-locked" class="box red-gradient">
        <h1>Gameweek Locked</h1>
        <div v-if="transfersGameweek" class="paragraphs">
            <p>
                Transfers and squad updates are locked for {{ transfersGameweek.locked_minutes }} minutes between gameweeks.
            </p>
            <p>
                This is to ensure that people don't make mistakes accidentally making changes to the next gameweek when they miss the deadline for a gameweek by a few seconds.
            </p>
            <p>
                Transfers will reopen: {{ openAtText }}
            </p>
        </div>
        <div v-else>
            <p>
                It is not possible to make transfers at this time.
            </p>
        </div>
        <countdown-timer v-if="transfersGameweek" :timestamp="transfersGameweek.open_at_timestamp">
            <template #onComplete>
                <div class="mt-3">
                    <button @click.once="reloadSeason">
                        Gameweek Open! Click here to reload
                    </button>
                </div>
            </template>
        </countdown-timer>
    </div>
</template>
<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";
import {useDefaultStore} from "../stores/defaultStore";
import dayjs from "dayjs";
import CountdownTimer from "@/components/CountdownTimer.vue";

export default {
    components: {CountdownTimer},

    setup(props) {
        const seasonStore = useSeasonStore()

        const pointsGameweek = computed(() => seasonStore.pointsGameweek)
        const transfersGameweek = computed(() => seasonStore.transfersGameweek)

        const openAtText = computed(() => {
            return dayjs(transfersGameweek.value.open_at).format('dddd MMMM D - h:mm A')
        })

        const reloadSeason = function() {
            seasonStore.init(seasonStore.seasonId)
        }

        return {pointsGameweek, transfersGameweek, openAtText, reloadSeason}
    }
}
</script>
