<template>
    <div>
        <div v-if="transfersGameweek.number > 1 && season.budget_status === 'pending_calculation'" class="box error">
            Transfers are locked while new dynamic pricing is calculated.
        </div>
        <div v-else-if="!gameweekIsOpen">
            <gameweek-locked-box></gameweek-locked-box>
        </div>
        <div v-else-if="!authEntry">
            <create-entry-form></create-entry-form>
        </div>
        <pick-squad v-else></pick-squad>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, onMounted, ref} from "vue";
import CreateEntryForm from "../components/CreateEntryForm.vue";
import PickSquad from "../components/PickSquad.vue";
import {useSeoMeta} from "@unhead/vue";
import GameweekLockedBox from "@/components/GameweekLockedBox.vue";

export default {
    components: {GameweekLockedBox, PickSquad, CreateEntryForm},
    setup() {
        const store = useSeasonStore()

        useSeoMeta({
            title: () => 'Transfers | ' + store.season.name,
            description: () => 'Pick Fantasy Squad for ' + store.season.name
        })

        const gameweekIsOpen = ref(null)

        onMounted(() => {
            gameweekIsOpen.value = store.gameweekIsOpen()
        })

        const season = computed(() => {
            return store.season
        })

        const authEntry = computed(() => {
            return store.authEntry
        })

        const transfersGameweek = computed(() => {
            return store.transfersGameweek
        })

        return {gameweekIsOpen, season, authEntry, transfersGameweek}
    }
}
</script>
