<template>
    <div v-if="appLoaded">
        <div id="flash-messages">
            <flash v-if="flashMessage" :state="flashState" @close="clearFlashMessage" :key="flashMessage">
                {{ flashMessage }}
            </flash>
            <flash v-if="flashCountdownTimestamp" state="warning" @close="clearFlashCountdown">
                {{ flashCountdownMessage }} <countdown-timer class="inline" :timestamp="flashCountdownTimestamp"></countdown-timer>
            </flash>
        </div>
        <div v-if="authUser || $route.meta.allowGuest">
            <router-view></router-view>
        </div>
        <div v-else>
            <auth-page></auth-page>
        </div>
    </div>
    <div v-else>
        <h2>Not Loaded</h2>
    </div>
</template>
<script>
import { useDefaultStore } from './stores/defaultStore'
import { useRoute } from 'vue-router'
import {computed, onMounted, ref, watch} from 'vue'
import AuthPage from "./pages/AuthPage.vue";
import {useHead} from "@unhead/vue";
import Flash from "@/components/Flash.vue";
import {useFlashStore} from "@/stores/flashStore";
import CountdownTimer from "@/components/CountdownTimer.vue";

export default {
    components: {CountdownTimer, Flash, AuthPage},
    setup() {
        const route = useRoute()
        const store = useDefaultStore()
        const flashStore = useFlashStore()

        const flashMessage = computed(() => {
            return flashStore.message
        })

        const flashState = computed(() => {
            return flashStore.state
        })

        const flashCountdownTimestamp = computed(() => {
            return flashStore.countdownTimestamp
        })

        const flashCountdownMessage = computed(() => {
            return flashStore.countdownMessage
        })

        const clearFlashMessage = function() {
            flashStore.clear()
        }

        const clearFlashCountdown = function() {
            flashStore.clearCountdown()
        }

        useHead({
            titleTemplate: '%s %separator %siteName',
            templateParams: { separator: '|', siteName: 'World Fantasy Soccer' },
        })

        onMounted(() => {
            store.init()

            /*
            if (window.Worker) {
                const worker = new Worker(new URL('@/worker.js', import.meta.url));
                worker.postMessage('');

                worker.addEventListener('message', function(e) {
                    counter.value = e.data
                });
            } else {
                console.error('Web Workers are not supported in your browser.');
            }
             */
        })

        const counter = ref(0)

        watch(
            () => route.path,
            async newId => {
                showNav.value = false
            }
        )

        const appLoaded = computed(() => {
            return store.appLoaded;
        })

        const title = computed(() => {
            return store.title
        })

        const authUser = computed(() => {
            return store.authUser;
        })

        const showNav = ref(false)

        return {counter, appLoaded, authUser, showNav, title, flashMessage, flashState, clearFlashMessage, flashCountdownTimestamp, flashCountdownMessage, clearFlashCountdown}
/*
        const authEntry = computed(() => {
            return store.authEntry;
        })

        const bankValue = computed(() => {
            return store.authEntry ? store.authEntry.bank / 10 : null;
        })

        const pointsGw = computed(() => {
            return store.pointsGameweek
        })

        const transfersGw = computed(() => {
            return store.transfersGameweek
        })

        const seasonId = computed(() => {
            return store.seasonId
        })

        const logout = () => {
            store.logout()
        }



        return {appLoaded, authUser, authEntry, bankValue, logout, showNav, pointsGw, transfersGw, title, seasonId}

 */
    }
}
</script>
