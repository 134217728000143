<template>
    <tr>
        <th>
            <span class="mx-3">{{ fixture.id }}</span>
        </th>
        <td>
            <select
                v-model="localDraft.gameweek_id"
                @change="emitChanges"
                @keydown.enter="saveData"
                :class="{ warning: localDraft.gameweek_id !== fixture.gameweek_id }"
            >
                <option v-for="gameweek in gameweeks" :value="gameweek.id">
                    GW {{ gameweek.number }}
                </option>
            </select>
        </td>
        <td>
            <select
                v-model="localDraft.status"
                :class="{ warning: localDraft.status !== fixture.status }"
                @change="emitChanges"
                @keydown.enter="saveData"
            >
                <option
                    v-for="(statusLabel, statusValue) in statusOptions"
                    :value="statusValue"
                >
                    {{ statusLabel }}
                </option>
            </select>
        </td>
        <td>
            <form @submit.prevent="saveData">
                <input
                    type="datetime-local"
                    v-model="localDraft.local_kickoff"
                    @input="emitChanges"
                    @keydown.enter="saveData"
                    placeholder="Kick Off"
                    :class="{ warning: localDraft.timestamp !== fixture.timestamp }"
                />
            </form>
        </td>
        <td>
            <img v-if="homeTeam.image_path" :src="homeTeam.image_path" style="width:16px;display:inline" />
            {{ homeTeam.name }}
        </td>
        <td>
            <form @submit.prevent="saveData">
                <input
                    type="number"
                    v-model="localDraft.home_team_score"
                    @input="emitChanges"
                    placeholder="Home Goals"
                    :class="{ warning: localDraft.home_team_score !== fixture.home_team_score }"
                />
            </form>
        </td>
        <td>
            <img v-if="awayTeam.image_path" :src="awayTeam.image_path" style="width:16px;display:inline" />
            {{ awayTeam.name }}
        </td>
        <td>
            <form @submit.prevent="saveData">
                <input
                    type="number"
                    v-model="localDraft.away_team_score"
                    @input="emitChanges"
                    placeholder="Away Goals"
                    :class="{ warning: localDraft.away_team_score !== fixture.away_team_score }"
                />
            </form>
        </td>
        <td>
            <router-link
                class="mx-3"
                :to="{name: 'mod-fixture', params: {fixtureId: fixture.id}}"
            >
                Edit
            </router-link>
        </td>
    </tr>
</template>

<script>
import {computed, ref, watch} from "vue";
import { useSeasonStore } from "../stores/seasonStore";
import dayjs from "dayjs";

export default {
    emits: ['update:modelValue', 'saveRow'],
    props: {
        fixture: {
            type: Object,
            required: true
        },
        modelValue: {
            type: Object,
            default: () => ({})
        },
        userTimezone: {
            type: String,
            required: true
        }
    },
    setup(props, { emit }) {
        const seasonStore = useSeasonStore()

        const localDraft = ref({ ...props.modelValue });

        watch(
            () => localDraft.value.local_kickoff,
            (newLocalKickoff) => {
                localDraft.value.kickoff = dayjs(newLocalKickoff).tz(props.userTimezone).utc().format('YYYY-MM-DD HH:mm:ss');
                localDraft.value.timestamp = dayjs(newLocalKickoff).utc().unix();
            }
        );

        const statusOptions = {
            NS: 'Scheduled',
            inprogress: 'In Progress',
            FT: 'Finished',
            postponed: 'Postponed',
        }

        const gameweek = computed(() => {
            return seasonStore.gameweeks[props.fixture.gameweek_id];
        })

        const gameweeks = computed(() => {
            return seasonStore.gameweeks;
        })

        const homeTeam = computed(() => {
            return seasonStore.teams[props.fixture.home_team_id];
        })

        const awayTeam = computed(() => {
            return seasonStore.teams[props.fixture.away_team_id];
        })

        watch(
            () => props.modelValue,
            (newVal) => {
                localDraft.value = { ...newVal }; // Update localDraft if modelValue changes
            }
        );

        const emitChanges = () => {
            emit('update:modelValue', localDraft.value);
        };

        const saveData = () => {
            emit('saveRow', props.fixture.id); // Emit event to save the row in parent
        };

        return { localDraft, statusOptions, gameweek, gameweeks, homeTeam, awayTeam, emitChanges, saveData };
    }
};
</script>
