<template>
    <div class="player-list-table">
        <table>
            <player-list-headers
                @selectColumn="selectColumn($event)"
                :columns="columns"
                :filter="filter"
            ></player-list-headers>
            <tbody>
            <player-list-row
                v-for="playerGameweek in sortedPlayerGameweeks"
                :key="playerGameweek.id"
                :player="players[playerGameweek.player_season_id]"
                :stats="playerGameweek"
                :pick="picks[playerGameweek.player_season_id]"
                :columns="columns"
                route-name="player">
            </player-list-row>
            </tbody>
        </table>
    </div>
</template>
<script>
import PlayerListHeaders from "@/components/PlayerListHeaders.vue";
import PlayerListRow from "@/components/PlayerListRow.vue";
import playerColumns from "@/config/PlayerColumns";
import {computed, ref} from "vue";
import {useSeasonStore} from "@/stores/seasonStore";

export default {
    props: {
        playerGameweeks: {
            type: Array,
            required: true
        },
        picks: {
            type: Object,
            required: false
        }
    },
    components: {PlayerListHeaders, PlayerListRow},
    setup(props) {
        const seasonStore = useSeasonStore()
        const columns = playerColumns.filterByModel('player_gameweek')

        const filter = ref({
            sort: null,
            sort_dir: null
        });

        const selectColumn = function(column) {
            const sortField = column.sort_field || column.field
            const sortDir = column.sort_dir || 'desc'
            if (filter.value.sort === sortField && filter.value.sort_dir !== sortDir) {
                filter.value = {
                    sort: null,
                    sort_dir: null
                }
            } else if(filter.value.sort === sortField) {
                filter.value = {
                    sort: sortField,
                    sort_dir: sortDir === 'asc' ? 'desc' : 'asc'
                }
            } else {
                filter.value = {
                    sort: sortField,
                    sort_dir: sortDir
                }
            }
        }

        const players = computed(() => {
            return _.pick(seasonStore.players, _.map(props.playerGameweeks, 'player_season_id'))
        })

        const sortedPlayerGameweeks = computed(() => {
            if (filter.value.sort === null) {
                return props.playerGameweeks
            } else {
                return _.orderBy(
                    props.playerGameweeks,
                    playerGameweek => {
                        if (filter.value.sort === 'position') {
                            return players.value[playerGameweek.player_season_id].position_sort
                        } else if (filter.value.sort === 'team') {
                            return seasonStore.teams[playerGameweek.team_id].short_name
                        } else if(playerGameweek.hasOwnProperty(filter.value.sort)) {
                            return playerGameweek[filter.value.sort]
                        } else {
                            return players.value[playerGameweek.player_season_id][filter.value.sort]
                        }
                    },
                    filter.value.sort_dir
                )
            }
        })

        return {
            columns,
            selectColumn,
            filter,
            players,
            sortedPlayerGameweeks
        }
    }
}
</script>
