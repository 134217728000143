import {defineStore} from 'pinia'

export const useFlashStore = defineStore('flashStore', {
    state: () => ({
        message: null,
        state: null,
        countdownMessage: null,
        countdownTimestamp: null
    }),
    actions: {
        setFlash(state, message) {
            this.state = state
            this.message = message
        },
        axiosError(error, defaultMessage) {
            console.error(error)
            this.state = 'error'
            this.message = _.get(error, 'response.data.message') || defaultMessage
        },
        clear() {
            this.message = null
            this.state = null
        },
        setCountdown(timestamp, message) {
            this.countdownTimestamp = timestamp
            this.countdownMessage = message
        },
        clearCountdown() {
            this.countdownTimestamp = null
            this.countdownMessage = null
        }
    }
})
