<template>
    <player-list></player-list>
</template>

<script>
import {useSeoMeta} from "@unhead/vue";
import {useSeasonStore} from "@/stores/seasonStore";
import PlayerList from "@/components/PlayerList.vue";

export default {
    components: {PlayerList},
    setup() {
        const seasonStore = useSeasonStore()

        useSeoMeta({
            title: () => 'All Players | Fantasy ' + seasonStore.season.name,
            description: () => 'All Players in ' + seasonStore.season.name
        })
    }
}
</script>
