<template>
    <div>
        <beta-text></beta-text>
        <div class="grid grid-cols-1 lg:grid-cols-2">
            <div>
                <fixtures :load-picks="true"></fixtures>
                <gameweeks></gameweeks>
            </div>
            <div>
                <template v-if="season.has_started">
                    <entry-leaderboard
                        v-if="authEntry && authEntry.rank > 0"
                        :entry="authEntry"
                        :season-id="season.id"
                        :per-page="20"
                    ></entry-leaderboard>
                    <overall-leaderboard
                        v-else
                        :per-page="20"
                        :show-controls="false"
                    ></overall-leaderboard>
                    <div class="text-center mt-3">
                        <router-link :to="{name:'leaderboard'}" class="btn success">View full leaderboard</router-link>
                    </div>
                </template>
            </div>
            <template v-if="pointsGw">
                <team-of-the-week></team-of-the-week>
                <transfer-stats></transfer-stats>
                <selection-stats></selection-stats>
                <price-change-stats v-if="season.budget"></price-change-stats>
            </template>
            <explanation class="p-3"></explanation>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, onMounted, ref} from "vue";
import Fixtures from "../components/Fixtures.vue";
import Standings from "../components/Standings.vue";
import BetaText from "../components/BetaText.vue";
import TeamOfTheWeek from "../components/TeamOfTheWeek.vue";
import TransferStats from "../components/TransferStats.vue";
import SelectionStats from "../components/SelectionStats.vue";
import Explanation from "../components/Explanation.vue";
import PriceChangeStats from "../components/PriceChangeStats.vue";
import Gameweeks from "@/components/Gameweeks.vue";
import {useSeoMeta} from "@unhead/vue";
import OverallLeaderboard from "@/components/OverallLeaderboard.vue";
import EntryLeaderboard from "@/components/EntryLeaderboard.vue";

export default {
    components: {
        EntryLeaderboard,
        OverallLeaderboard,
        Gameweeks,
        PriceChangeStats,
        Explanation,
        SelectionStats,
        TransferStats,
        TeamOfTheWeek,
        BetaText,
        Standings,
        Fixtures
    },
    setup(props) {
        const store = useSeasonStore()

        useSeoMeta({
            title: () => 'Fantasy ' + store.season.name,
            description: () => 'Play Fantasy ' + store.season.name + ' the best Fantasy Football App for ' + store.season.name
        })

        const pointsGw = computed(() => store.pointsGameweek)
        const season = computed(() => store.season)

        const authEntry = computed(() => {
            return store.authEntry
        })

        return {pointsGw, season, authEntry}
    }
}
</script>
