<template>
    <tr>
        <th>
            <span class="mx-3">{{ gameweek.id }}</span>
        </th>
        <th>
            <span class="mx-3">{{ gameweek.number }}</span>
        </th>
        <td>
            {{ gameweek.status }}
        </td>
        <td>
            {{ startAt }}
        </td>
        <td>
            {{ endAt }}
        </td>
        <td>
            {{ lockedAt }}
        </td>
        <td v-for="numericInput in numericInputs">
            <form @submit.prevent="saveData">
                <input
                    type="number"
                    v-model="localDraft[numericInput]"
                    @input="emitChanges"
                    :placeholder="numericInput"
                    :class="{ warning: localDraft[numericInput] !== gameweek[numericInput] }"
                />
            </form>
        </td>
        <td>
            <span v-if="gameweek.calculated_auto_subs">Yes</span>
            <span v-else>No</span>
        </td>
    </tr>
</template>

<script>
import {computed, ref, watch} from "vue";
import { useSeasonStore } from "../stores/seasonStore";
import { useFlashStore } from "@/stores/flashStore";
import dayjs from "dayjs";

export default {
    emits: ['update:modelValue', 'saveRow'],
    props: {
        gameweek: {
            type: Object,
            required: true
        },
        modelValue: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props, { emit }) {
        const numericInputs = ['free_transfers', 'rollover_free_transfers', 'max_free_transfers', 'cost_per_transfer', 'picks_per_team']

        const localDraft = ref({ ...props.modelValue });

        const lockedAt = computed(() => {
            return dayjs(props.gameweek.locked_at).format('YYYY-MM-DD HH:mm');
        })

        const startAt = computed(() => {
            return dayjs(props.gameweek.start_at).format('YYYY-MM-DD HH:mm');
        })

        const endAt = computed(() => {
            return dayjs(props.gameweek.end_at).format('YYYY-MM-DD HH:mm');
        })

        watch(
            () => props.modelValue,
            (newVal) => {
                localDraft.value = { ...newVal }; // Update localDraft if modelValue changes
            }
        );

        const emitChanges = () => {
            emit('update:modelValue', localDraft.value);
        };

        const saveData = () => {
            emit('saveRow', props.gameweek.id); // Emit event to save the row in parent
        };

        return { numericInputs, localDraft, lockedAt, startAt, endAt, emitChanges, saveData };
    }
};
</script>
