<template>
    <div id="lost-password-form" class="form box">
        <h1>Lost Password</h1>
        <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="lost_password_email">Your email</label>
                <input type="email" id="lost_password_email"
                       class=""
                       placeholder="" required
                        v-model="email">
            </div>
            <div class="mb-3">
                <button>
                    Retrieve Lost Password
                </button>
            </div>
        </form>
    </div>
</template>
<script>
import {computed, ref} from "vue";
import {useDefaultStore} from "../stores/defaultStore.js";
import {useFlashStore} from "@/stores/flashStore";

export default {
    emits: ['success', 'error'],
    setup(props, {emit}) {
        const store = useDefaultStore()

        const email = ref('')

        const submitForm = () => {
            axios
                .post('/api/auth/lost-password', {
                    email: email.value
                })
                .then(response => {
                    useFlashStore().setFlash('success', 'Request sent. Check your email')
                    emit('success', response)
                })
                .catch(error =>  {
                    useFlashStore().axiosError(error, 'Error requesting password reset email')
                    emit('error', error)
                })
        }

        return {email, submitForm}
    }
}
</script>
