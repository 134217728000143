<template>
    <transfers-list route-name="admin-player"></transfers-list>
</template>

<script>
import TransfersList from "@/components/TransfersList.vue";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {TransfersList},
    setup(props) {
        useSeoMeta({
            title: () => 'God Transfers',
            description: () => 'God Transfers Page'
        })
    }
}
</script>
